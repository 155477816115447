import { useContext } from 'react'

import { AuthenticationContext } from '../authentication/authenticationContext'

import IntegrationSettings from '../accountSettings/IntegrationSettings'
import ZapierSettings from '../accountSettings/ZapierSettings'

const LeadsIntegrationsParent = () => {
  const { authData } = useContext(AuthenticationContext)
  const { user } = authData

  return (
    <>
      <IntegrationSettings />
      <ZapierSettings user={user} />
    </>
  )
}

export default LeadsIntegrationsParent

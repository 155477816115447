export const sanitizeUserName = (str) =>
  // Replace non-alphanumeric characters with an empty string
  str ? str.replace(/[^A-Za-z0-9]/g, '').toLowerCase() : ''

export const capitalize = (str) => {
  if (typeof str !== 'string') return ''
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const randomCodeString = (length) => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  let result = ''
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length))
  }
  return result
}

import React, { useContext } from 'react'

import { styled } from '@mui/system'
import { Typography } from '@mui/material'

import { AuthenticationContext } from '../authentication/authenticationContext'

import PageHeader from '../common/PageHeader'
import ProfileSettings from './ProfileSettings'
import MainUserSettings from './MainUserSettings'
import ConfigurationOptions from './ConfigurationOptions'
import SocialMediaSettings from './SocialMediaSettings'

import './AccountSettings.scss'

const AccountSettingsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
})

export const AccountSettingsCard = styled('div')(({ theme }) => ({
  padding: '25px',
  backgroundColor: 'white',
  marginBottom: theme.spacing(2),
  h5: {
    textAlign: 'left',
  },
}))

export const AccountSettingsCardInner = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
  marginTop: '10px',
}))

export const AccountSettingsCardColumn = styled('div')({
  textAlign: 'left',
  width: '100%',
})

export const AccountSettingsCardTitle = styled(Typography)({
  fontWeight: 'bold',
})

const AccountSettingsParent = () => {
  const { authData } = useContext(AuthenticationContext)
  const { user } = authData

  return (
    <>
      <PageHeader header="Account Settings" />
      <AccountSettingsContainer>
        <MainUserSettings />
        <ConfigurationOptions />
        <SocialMediaSettings />
        <ProfileSettings user={user} />
      </AccountSettingsContainer>
    </>
  )
}

export default AccountSettingsParent

import React from 'react'
import talkadotLogo from '../../icons/talkadot-logo.png'
import { Typography } from '@mui/material'
import { styled } from '@mui/system'

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: '10px',
})

const WelcomeHeading = styled(Typography)({
  textAlign: 'center',
  marginTop: '20px',
  fontWeight: '300',
})

const WelcomeSubheading = styled(Typography)({
  fontWeight: 300,
  fontSize: '18px',
  margin: '10px 0',
})

const TalkadotLogo = styled('img')({
  maxWidth: '200px',
})

const FreeTrialNotice = styled(Typography)({
  fontWeight: 200,
  fontSize: '12px',
})

const RegistrationHeader = ({
  referrer,
  plan,
  genericWelcomeMessage,
  hideSubheader,
  groupName,
}) => {
  const renderReferrerWelcomeMessage = () => (
    <div>
      <WelcomeHeading variant="h5">
        <b>
          Glad to see <span>{referrer}</span> refer you to us!
        </b>
      </WelcomeHeading>
      {!hideSubheader && (
        <WelcomeSubheading component="div">
          {plan?.trialPeriodDays > 0
            ? `As a bonus, get a free trial of ${plan.name} for ${plan.trialPeriodDays} days, and a discounted price to upgrade!`
            : "Let's get you started on your Talkadot account"}
        </WelcomeSubheading>
      )}
      {plan?.trialPeriodDays > 0 && (
        <FreeTrialNotice variant="body2">
          {`(You will automatically downgrade to Talkadot Lite after the ${plan.trialPeriodDays} day trial)`}
        </FreeTrialNotice>
      )}
    </div>
  )

  const renderGroupInvitationWelcomeMessage = () => (
    <div>
      <WelcomeHeading variant="h5">
        <b>Sign up now to accept your {groupName} invite!</b>
      </WelcomeHeading>
      {!hideSubheader && (
        <WelcomeSubheading component="div">
          {plan?.trialPeriodDays > 0
            ? `As a bonus, get a free trial of ${plan.name} for ${plan.trialPeriodDays} days, and a discounted price to upgrade!`
            : "Let's get you started on your Talkadot account"}
        </WelcomeSubheading>
      )}
      {plan?.trialPeriodDays > 0 && (
        <FreeTrialNotice variant="body2">
          {`(You will automatically downgrade to Talkadot Lite after the
          ${plan.trialPeriodDays} day trial)`}
        </FreeTrialNotice>
      )}
    </div>
  )

  const renderGenericWelcomeMessage = () => (
    <div>
      <WelcomeHeading component="div" variant="h5">
        <b>{genericWelcomeMessage}</b>
      </WelcomeHeading>
      {!hideSubheader && (
        <WelcomeSubheading component="div">
          {plan?.trialPeriodDays > 0
            ? `Enjoy a free trial of ${plan.name} for ${plan.trialPeriodDays} days!`
            : "Let's get you started on your Talkadot account"}
        </WelcomeSubheading>
      )}
      {plan?.trialPeriodDays > 0 && (
        <FreeTrialNotice variant="body2">
          {`(You will automatically downgrade to Talkadot Lite after the ${plan.trialPeriodDays} day trial)`}
        </FreeTrialNotice>
      )}
    </div>
  )

  return (
    <Container>
      <TalkadotLogo src={talkadotLogo} alt="talkadot logo" />
      {referrer
        ? renderReferrerWelcomeMessage()
        : groupName
        ? renderGroupInvitationWelcomeMessage()
        : renderGenericWelcomeMessage()}
    </Container>
  )
}

export default RegistrationHeader

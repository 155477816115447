import React, { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Typography,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
} from '@mui/material'
import AdminSearchBar from './AdminSearchBar'
import { DataGrid } from '@mui/x-data-grid'
import { styled } from '@mui/system'

import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import { AdminContext } from './adminContext'
import ShareIcon from '@mui/icons-material/Share'
import * as Helpers from '../common/helpers'
import EditUserMembershipDetails from './EditUserMembershipDetails'
import UpdateUserSettings from './UpdateUserSettings'
import DeleteUserParent from './DeleteUserParent'

import './Admin.scss'

const ButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  columnGap: '10px',
})

const AdminParent = () => {
  const {
    impersonateUser,
    fetchAvailableMembershipPlans,
    fetchAvailableMembershipStatusus,
    fetchUsers,
    fetchReferralUserOptions,
    adminState,
    updateUser,
    deleteSpeaker,
  } = useContext(AdminContext)
  const history = useHistory()

  const { users } = adminState

  useEffect(() => {
    fetchAvailableMembershipPlans()
    fetchAvailableMembershipStatusus()
    fetchReferralUserOptions()
  }, [])

  const ImpersonateUserAction = ({ user }) => {
    const [open, setOpen] = useState(false)

    return (
      <div>
        <Tooltip placement="top" title="Impersonate User">
          <PersonOutlineIcon onClick={() => setOpen(true)} />
        </Tooltip>
        <Dialog
          fullWidth
          maxWidth="sm"
          open={open}
          onClose={() => setOpen(false)}>
          <DialogTitle>Impersonate User</DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              You are about to impersonate{' '}
              <b>
                {user.first_name} {user.last_name}
              </b>
              . Are you sure you want to do this? You will be redirected to
              their page.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button
              className="orange-button"
              onClick={() => impersonateUser(user)}>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }

  const CopyAffiliateLinkAction = ({ user }) => {
    const [copied, setCopied] = useState(false)
    const handleCopy = () => {
      Helpers.handleCopy(user.referrerLink)
      setCopied(true)

      setTimeout(() => {
        setCopied(false)
      }, 5000)
    }

    return (
      <Tooltip
        placement="top"
        title={copied ? 'Copied!' : 'Copy Referral Link'}>
        <ShareIcon onClick={handleCopy} />
      </Tooltip>
    )
  }

  const columns = [
    {
      field: 'first_name',
      headerName: 'First name',
      sortable: true,
      align: 'center',
      headerAlign: 'center',
      flex: 1,
    },
    {
      field: 'last_name',
      headerName: 'Last name',
      sortable: true,
      align: 'center',
      headerAlign: 'center',
      flex: 1,
    },
    {
      field: 'email',
      headerName: 'email',
      sortable: true,
      align: 'center',
      headerAlign: 'center',
      flex: 1,
    },
    {
      field: 'shortlink',
      headerName: 'Username',
      sortable: true,
      align: 'center',
      headerAlign: 'center',
      flex: 1,
    },
    {
      field: 'created_at',
      headerName: 'Registration Date',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'membership_plan',
      headerName: 'Current Plan',
      flex: 1,
      align: 'center',
      sortable: true,
      headerAlign: 'center',
      renderCell: (params) => (
        <div>
          {params.row.membership_plan.name}(
          {params.row.membership_plan.internal_name})
        </div>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <div className="admin-parent__actions">
          <ImpersonateUserAction user={params.row} />
          <EditUserMembershipDetails user={params.row} />
          <UpdateUserSettings user={params.row} updateUser={updateUser} />
          <CopyAffiliateLinkAction user={params.row} />
          <DeleteUserParent user={params.row} deleteSpeaker={deleteSpeaker} />
        </div>
      ),
    },
  ]

  return (
    <div className="admin-parent">
      <ButtonContainer>
        <Button
          variant="contained"
          color="primary"
          onClick={() => history.push('/admin/chapter_uploads')}>
          Chapter Uploads
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => history.push('/admin/associates')}>
          View Associate Accounts
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => history.push('/admin/commission-payouts')}>
          Upload Commission Transactions
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => history.push('/admin/referral-uploads')}>
          Backfill Referral Data
        </Button>
      </ButtonContainer>

      <div style={{ height: 820, width: '100%' }}>
        <div style={{ display: 'flex', height: '100%', width: '100%' }}>
          <div style={{ flexGrow: 1 }}>
            <AdminSearchBar fetchUsers={fetchUsers} />
            <DataGrid
              localeText={{
                noRowsLabel: 'No data returned for that search term',
              }}
              rows={users}
              columns={columns}
              pageSize={25}
              rowsPerPageOptions={[25]}
              componentsProps={{
                toolbar: { csvOptions: { disableToolbarButton: true } },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminParent

import { useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { styled } from '@mui/system'

import PageHeader from '../../common/PageHeader'
import CodeBuilderQuestion from './CodeBuilderQuestion'
import CodeBuilderNavigation from './CodeBuilderNavigation'
import CodeBuilderPreviewPaneParent from './CodeBuilderPreviewPaneParent'

import { AuthenticationContext } from '../../authentication/authenticationContext'

import { randomCodeString } from '../../../utils/string'

const CodeBuilderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(5),
}))

const QuestionContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  height: 'min-content',
  padding: theme.spacing(5, 2.5),
  backgroundColor: theme.palette.base.white,
}))

const initialQuestionState = {
  questionType: '',
}

const CodeBuilderParent = () => {
  const [surveyOffer, setSurveyOffer] = useState({})
  const [code, setCode] = useState('')
  const [questions, setQuestions] = useState([])
  const [currentQuestion, setCurrentQuestion] = useState(initialQuestionState)
  const { surveyOfferId } = useParams()

  const { authData } = useContext(AuthenticationContext)
  const { user } = authData

  useEffect(() => {
    // if surveyOfferId is not null, fetch the survey offer
    // this means the user is editing an existing survey offer
    if (surveyOfferId) {
      // fetch survey offer
      // setQuestions
      // setCurrentQuestion to first question
      // setCode to surveyOffer.code
    }

    // if surveyOfferId is null, set currentQuestion to 'code'
    // this means the user is creating a new survey offer
    setCurrentQuestion({ questionType: 'code' })
    setCode(randomCodeString(4))
  }, [surveyOfferId])

  const handleBack = () => {
    // get index of currentQuestion
    // set currentQuestion to previous question
  }

  const handleNext = () => {
    // get index of currentQuestion
    // set currentQuestion to next question
    // if surveyOffer.id does not exist, create a new survey offer
    // if surveyOffer.id exists, go to next question (handleBlur should have updated any changes)
  }

  const handleSkip = () => {
    // submit and set currentQuestion to bonus question
  }

  const questionNumber = () => {
    if (currentQuestion.questionType === 'code') {
      return 1
    }

    if (currentQuestion.questionType === 'thankyou') {
      return questions.length + 2
    }

    return questions.findIndex((question) => question === currentQuestion) + 1
  }

  return (
    <>
      <PageHeader header="Your Talkadot Code Setup" />
      <CodeBuilderContainer>
        <QuestionContainer>
          <CodeBuilderQuestion
            surveyOffer={surveyOffer}
            question={currentQuestion}
            questionNumber={questionNumber()}
            code={code}
            setCode={setCode}
          />
          <CodeBuilderNavigation
            handleBack={handleBack}
            handleNext={handleNext}
            handleSkip={handleSkip}
            showBackButton={currentQuestion.questionType !== 'code'}
            showBuilderSkipButton={
              !surveyOffer?.id && currentQuestion.questionType === 'code'
            }
          />
        </QuestionContainer>
        <CodeBuilderPreviewPaneParent
          currentQuestion={currentQuestion}
          setCurrentQuestion={setCurrentQuestion}
          questions={questions}
          user={user}
          code={code}
        />
      </CodeBuilderContainer>
    </>
  )
}

export default CodeBuilderParent

import React, { useEffect, useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import { Tooltip } from '@material-ui/core'
import ConfirmDialog from '../common/ConfirmDialog'
import Api from '../../services/api'

const initialConfirmProps = {
  title: '',
  onConfirm: () => {},
  onCancel: () => {},
  buttonColor: '',
  buttonText: '',
  cancelButtonText: '',
  manualConfirm: false,
  columnLayout: false,
  confirmText: '',
  children: '',
}

const DeleteUserParent = ({ user, deleteSpeaker }) => {
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [confirmProps, setConfirmProps] = useState(initialConfirmProps)

  const handleConfirmDelete = async () => {
    await deleteSpeaker(user.id)
    setConfirmOpen(false)
  }

  const handleDeleteUser = async () => {
    try {
      const res = await Api.adminFetchSpeaker(user.id)

      if (!res.errors) {
        setConfirmProps({
          title: 'Are you sure you want to delete this user?',
          onConfirm: handleConfirmDelete,
          buttonColor: 'black',
          buttonText:
            'I understand the consequences, delete this user and related data',
          cancelButtonText: 'Cancel request, keep this user',
          manualConfirm: true,
          columnLayout: true,
          confirmText: user.email.toUpperCase(),
          children: (
            <div>
              You will be deleting {user.first_name} {user.last_name}'s account,
              and all related data - all of their events, leads, codes,
              submissions, and all other data be deleted.
              <ul>
                <li>Membership Plan: {user.membership_plan.name}</li>
                <li>Leads: {res.leadCount}</li>
                <li>Events: {res.eventCount}</li>
                <li>Codes: {res.codeCount}</li>
              </ul>
            </div>
          ),
        })
        setConfirmOpen(true)
      } else {
        throw res.errors
      }
    } catch (err) {
      alert('Woops! Something went wrong loading meta data for this speaker')
    }
  }

  return (
    <>
      <Tooltip title="Delete User" placement="top">
        <DeleteIcon onClick={handleDeleteUser} />
      </Tooltip>
      <ConfirmDialog
        title={confirmProps.title}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={confirmProps.onConfirm}
        onCancel={confirmProps.onCancel}
        buttonColor={confirmProps.buttonColor}
        buttonText={confirmProps.buttonText}
        cancelButtonText={confirmProps.cancelButtonText}
        manualConfirm={confirmProps.manualConfirm}
        columnLayout={confirmProps.columnLayout}
        confirmText={confirmProps.confirmText}>
        {confirmProps.children}
      </ConfirmDialog>
    </>
  )
}

export default DeleteUserParent

import { useContext } from 'react'

import { Typography } from '@mui/material'
import { styled } from '@mui/system'

import { AuthenticationContext } from '../authentication/authenticationContext'

import { Notification } from '../common/Notification'
import PageHeader from '../common/PageHeader'
import ReferralParent from '../referrals/ReferralParent'

const NotificationContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(3, 0),
}))

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral.dark,
  fontWeight: 600,
}))

const ReferParent = () => {
  const {
    authData: {
      user: {
        membership: { shouldShowUpgrades },
      },
    },
  } = useContext(AuthenticationContext)

  return (
    <>
      <PageHeader
        header={'Refer and Earn'}
        showUpgradeButton={shouldShowUpgrades}
      />
      <NotificationContainer>
        <Notification variant="greyWarning" hideIcon hideClose maxWidth>
          <StyledTypography variant="body1">
            Talkadot’s grown almost entirely through word of mouth. Help us
            spread the word and receive our thanks!
          </StyledTypography>
        </Notification>
      </NotificationContainer>
      <ReferralParent />
    </>
  )
}

export default ReferParent

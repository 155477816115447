import React, { useEffect, useContext, useState } from 'react'
import { AdminContext } from './adminContext'
import EditIcon from '@mui/icons-material/Edit'
import {
  Typography,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  Select,
  MenuItem,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
  TextField,
  Autocomplete,
} from '@mui/material'
import { styled } from '@mui/system'
import moment from 'moment'
import { removeEmptysAndNulls } from '../common/helpers'

const MembershipSelect = styled(Select)({
  minWidth: '250px',
})

const initialReferrerUser = {
  label: '',
  id: '',
}

const EditUserMembershipDetails = ({ user }) => {
  const [open, setOpen] = useState(false)
  const [newPlan, setNewPlan] = useState({})
  const [originalPlan, setOriginalPlan] = useState({})
  const [trialDaysRemaining, setTrialDaysRemaining] = useState()
  const [membershipStatus, setMembershipStatus] = useState()
  const [referrerUser, setReferrerUser] = useState(initialReferrerUser)
  const [referrerUsers, setReferrerUsers] = useState([])

  const { updateMembership, adminState, updateUser } = useContext(AdminContext)

  useEffect(() => {
    setReferrerUser({
      label: user.referrerUser?.email,
      id: user.referrerUser?.id,
    })
  }, [user])

  useEffect(() => {
    const users = adminState?.referrerUserOptions
      ?.filter((refUser) => refUser?.id !== user?.id)
      .map((refUser) => ({
        id: refUser.id,
        label: `${refUser.first_name} ${refUser.last_name} - ${refUser.email}`,
      }))
    setReferrerUsers(users)
  }, [adminState?.referrerUserOptions, user?.id])

  const handleUpdateMembership = async () => {
    const updateParams = {
      membership_plan_id: newPlan.id,
      original_plan_id: originalPlan.id,
      trial_days_remaining: trialDaysRemaining,
      status: membershipStatus,
    }

    await updateMembership(removeEmptysAndNulls(updateParams), user)

    setOpen(false)
  }

  const handleReferrerUserChange = (event, value) => {
    // NOTE: for some reason the value always comes back as the entire option object instead of just the id
    // not sure how to fix this but this is causing the warning in the console

    // If clearing referrer, reset the value
    if (!value?.id) {
      setReferrerUser(initialReferrerUser)
      return
    } else {
      setReferrerUser(value)
    }

    updateUser(user, {
      referrer_user_id: value?.id || '',
    })
  }

  return (
    <div>
      <Tooltip placement="top" title="Edit Membership">
        <EditIcon onClick={() => setOpen(true)} />
      </Tooltip>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={() => setOpen(false)}>
        <DialogTitle>
          <Typography>
            Update membership details for{' '}
            <b>
              {user.first_name} {user.last_name}
            </b>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    <b>Attribute</b>
                  </TableCell>
                  <TableCell align="left">
                    <b>Current</b>
                  </TableCell>
                  <TableCell align="left">
                    <b>Proposed Change</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Referrer</TableCell>
                  <TableCell align="left">{user.referrerUser?.email}</TableCell>
                  <TableCell align="left">
                    <Autocomplete
                      options={referrerUsers}
                      isOptionEqualToValue={(option, value) =>
                        option?.id === value?.id
                      }
                      value={referrerUser?.id}
                      inputValue={referrerUser?.label}
                      onChange={handleReferrerUserChange}
                      renderInput={(params) => (
                        <TextField {...params} label="Referrer" />
                      )}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Membership Plan</TableCell>
                  <TableCell align="left">
                    {user.membership_plan.name}
                  </TableCell>
                  <TableCell align="left">
                    <MembershipSelect
                      value={newPlan}
                      displayEmpty
                      onChange={(e) => setNewPlan(e.target.value)}>
                      {adminState?.availableMembershipPlans?.map((plan, i) => {
                        return (
                          plan.id !== user.membership_plan.id && (
                            <MenuItem value={plan} key={i}>
                              {plan.name}({plan.internalName}) -{' '}
                              {plan.price ? `$${plan.price}` : 'FREE'} -{' '}
                              {plan.leadsLimit} leads -{' '}
                              {moment(plan.createdAt).format('MMM D YYYY')}
                            </MenuItem>
                          )
                        )
                      })}
                    </MembershipSelect>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <div>Original Plan</div>
                    <div>
                      <i>
                        (plan to let them upgrade to if on a trial and
                        downgraded)
                      </i>
                    </div>
                  </TableCell>
                  <TableCell align="left">
                    {user.membership.originalPlan.name} ($
                    {user.membership.originalPlan.price})
                  </TableCell>
                  <TableCell align="left">
                    <MembershipSelect
                      value={originalPlan}
                      onChange={(e) => setOriginalPlan(e.target.value)}>
                      {adminState?.availableMembershipPlans?.map((plan, i) => {
                        return (
                          <MenuItem value={plan} key={i}>
                            {plan.name}({plan.internalName}) -{' '}
                            {plan.price ? `$${plan.price}` : 'FREE'} -{' '}
                            {plan.leadsLimit} leads
                          </MenuItem>
                        )
                      })}
                    </MembershipSelect>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <div>Trial Days Remaining</div>
                    <div>
                      <i>(leave blank if not applicable)</i>
                    </div>
                  </TableCell>
                  <TableCell align="left">
                    {user.membership.trialDaysRemaining}
                  </TableCell>
                  <TableCell align="left">
                    <TextField
                      type="number"
                      value={trialDaysRemaining}
                      onChange={(e) => {
                        let value = e.target.value
                        if (value < 0) value = 0
                        setTrialDaysRemaining(value)
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Membership Status</TableCell>
                  <TableCell align="left">{user.membership.status}</TableCell>
                  <TableCell align="left">
                    <MembershipSelect
                      value={membershipStatus}
                      onChange={(e) => setMembershipStatus(e.target.value)}>
                      {adminState?.availableMembershipStatusus?.map(
                        (status, i) => {
                          return (
                            <MenuItem value={status} key={i}>
                              {status}
                            </MenuItem>
                          )
                        }
                      )}
                    </MembershipSelect>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button className="orange-button" onClick={handleUpdateMembership}>
            Confirm Membership Plan Switch
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default EditUserMembershipDetails

import { useContext, useEffect, useState } from 'react'

import { AuthenticationContext } from '../../authentication/authenticationContext'
import Api from '../../../services/api'

import DashboardSubheader from './DashboardSubheader'
import NotificationParent from './NotificationParent'
import ReferralParent from '../../referrals/ReferralParent'
import TrialProgressParent from './TrialProgressParent'
import VideoParent from './VideoParent'
import PageHeader from '../../common/PageHeader'

const DashboardParent = () => {
  const [isNewUser, setIsNewUser] = useState(false)
  const [notifications, setNotifications] = useState([])
  const [trialProgressData, setTrialProgressData] = useState(null)
  const [loading, setLoading] = useState(true)

  const { authData, setNotification } = useContext(AuthenticationContext)
  const {
    user: {
      first_name,
      isAssociate,
      membership: { shouldShowUpgrades },
    },
  } = authData

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const res = await Api.fetchNotifications()
        if (!res.errors) {
          setLoading(false)
          setNotifications(res.notifications)
          setTrialProgressData(res.trialProgressData)
          setIsNewUser(res.isNewUser)
        } else {
          throw res.errors
        }
      } catch (error) {
        setNotification(error, 'error')
      }
    }

    fetchNotifications()
  }, [])

  const renderHeaderText = () => {
    if (isAssociate) {
      return `👋 Welcome to ${first_name}'s Account!`
    } else {
      return `👋 Welcome back ${first_name}!`
    }
  }

  const renderTrialProgress = () => {
    if (trialProgressData) {
      return <TrialProgressParent {...trialProgressData} />
    }
  }

  if (loading) {
    return <></>
  }

  return (
    <>
      <PageHeader
        header={renderHeaderText()}
        showUpgradeButton={shouldShowUpgrades}
      />
      <DashboardSubheader isNewUser={isNewUser} />
      {notifications && <NotificationParent notifications={notifications} />}
      {renderTrialProgress()}
      <VideoParent />
      <ReferralParent />
    </>
  )
}

export default DashboardParent

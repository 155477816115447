import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { styled } from '@mui/system'
import {
  Typography,
  LinearProgress,
  IconButton,
  // Tooltip
} from '@mui/material'

import UpgradeButton from './UpgradeButton'

import { ReactComponent as ArrowLeft } from '../../icons/arrowLeft.svg'
// import { ReactComponent as InfoIcon } from '../../icons/infoIcon_16x16.svg'

import { AuthenticationContext } from '../authentication/authenticationContext'
import {
  usePermissionHelper,
  OFFER_CODE_LIMIT,
  EMAIL_CAMPAIGNS,
  LEADS_LIMIT,
} from '../../utils/permission_helper'
import { usePageType } from '../../utils/pathHelper'

const UpgradeWidgetContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'status',
})(({ theme, status }) => ({
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  gap: theme.spacing(1.125),
  padding: theme.spacing(2, 1.125),
  cursor: 'pointer',
  backgroundColor:
    status === 'urgent'
      ? theme.palette.primary.semiDark
      : theme.palette.neutral.semiDark,
  borderRadius: theme.shape.borderRadius.sm,
}))

const ConsumptionContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
})

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.base.white,
  fontWeight: '600',
  textWrap: 'wrap',
}))

const SubTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'status',
})(({ theme, status }) => ({
  color:
    status === 'urgent'
      ? theme.palette.base.white
      : theme.palette.neutral.light,
  marginBottom: theme.spacing(1.5),
}))

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  border: `1px solid ${theme.palette.base.white}`,
  borderRadius: '18px',
  height: '18px',
  marginBottom: theme.spacing(1.5),
}))

const CtaContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
}))

const CtaText = styled(Typography)(({ theme }) => ({
  color: theme.palette.base.white,
  textWrap: 'wrap',
}))

const ArrowButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'status' && prop !== 'renderCta',
})(({ theme, status, renderCta }) => ({
  visibility: renderCta ? 'visible' : 'hidden',
  transform: 'rotate(180deg)',
  backgroundColor:
    status === 'urgent'
      ? theme.palette.primary.main
      : theme.palette.neutral.main,
  svg: {
    path: {
      stroke: theme.palette.base.white,
    },
  },
  '&:hover': {
    backgroundColor:
      status === 'urgent'
        ? theme.palette.primary.light
        : theme.palette.neutral.semiLight,
  },
}))

// const ToolTipContentWrapper = styled('div')(({ theme }) => ({
//   display: 'flex',
//   alignItems: 'center',
//   gap: '4px',
// }))

// const StyledInfoIcon = styled(InfoIcon, {
//   shouldForwardProp: (prop) => prop !== 'status',
// })(({ theme, status }) => ({
//   // fill:
//   //   status === 'urgent'
//   //     ? theme.palette.base.white
//   //     : theme.palette.neutral.light,
//   // stroke:
//   //   status === 'urgent'
//   //     ? theme.palette.base.white
//   //     : theme.palette.neutral.light,
// }))

const pageTypeToPermissionDict = {
  codes: OFFER_CODE_LIMIT,
  leads: LEADS_LIMIT,
  emailCampaigns: EMAIL_CAMPAIGNS,
}

const permissionWidgetData = (
  { type, consumed, limit, enabled, consumedPercentage },
  shouldShowUpgrades,
  toggleUpgradeModal
) => {
  // Currently, only the 'EMAIL_CAMPAIGNS' is !enabled on some plans
  // Hardcoding the widget data for this scenario
  // TODO: Refactor to handle more scenarios in the future
  if (!enabled) {
    return {
      title: 'Email Followup',
      limitExceeded: true,
      consumedPercentage: 100,
      ctaText: 'Upgrade to Access Email followups',
      upgradeHelperText: 'Upgrade to Access Email followups',
      ctaClickHandler: () => toggleUpgradeModal(true, upgradeHelperText, type),
      renderCta: true,
    }
  }

  let data
  let upgradeHelperText
  const limitExceeded = consumed >= limit
  const renderCta = shouldShowUpgrades

  const commonData = {
    consumedPercentage: consumedPercentage(),
    limitExceeded,
    renderCta,
  }

  switch (type) {
    case 'LEADS_LIMIT':
      data = {
        title: limitExceeded ? 'Contact limit exceeded' : 'Contacts collected',
        subTitle: `${consumed}/${limit}`,
        // <Tooltip
        //   title="This number included deleted Leads."
        //   placement="right-end"
        //   disableInteractive>
        //   <ToolTipContentWrapper>
        //     {`${consumed}/${limit}`}
        //     <StyledInfoIcon />
        //   </ToolTipContentWrapper>
        // </Tooltip>
        ctaText: shouldShowUpgrades
          ? 'Upgrade for more contacts'
          : 'Contact us at hello@talkadot.com',
        upgradeHelperText: shouldShowUpgrades
          ? 'Upgrade for more contacts'
          : '',
      }
      break
    case 'OFFER_CODE_LIMIT':
      data = {
        title: limitExceeded ? 'Code limit reached' : 'Codes created',
        subTitle: `${consumed}/${limit}`,
        ctaText: shouldShowUpgrades
          ? 'Upgrade for more codes'
          : 'Contact us at hello@talkadot.com',
        upgradeHelperText: shouldShowUpgrades ? 'Upgrade for more codes' : '',
      }
      break
    case 'EMAIL_CAMPAIGNS':
      data = {
        title: limitExceeded
          ? 'Email followup limit reached'
          : 'Followup emails sent',
        subTitle: `${consumed}/${limit}`,
        ctaText: shouldShowUpgrades
          ? 'Upgrade for more emails'
          : 'Contact us at hello@talkadot.com',
        upgradeHelperText: shouldShowUpgrades ? 'Upgrade for more emails' : '',
      }
      break
    default:
      data = {}
      break
  }

  return {
    ...commonData,
    ...data,
  }
}

const UpgradeWidget = () => {
  const {
    toggleUpgradeModal,
    authData: {
      user: {
        membership: {
          isTrialing,
          trialDaysRemaining,
          shouldShowUpgrades,
          plan: { planType, trialPeriodDays },
        },
        billingInfo: { paymentErrorMessage, isExpired },
      },
    },
  } = useContext(AuthenticationContext)
  const history = useHistory()
  const { findPermission, onPaidPlan } = usePermissionHelper()
  const pageType = usePageType()
  const permissionType = pageTypeToPermissionDict[pageType]
  const activePermission = permissionType
    ? findPermission(permissionType)
    : null

  // The widget type can be set for multiple scenarios, prioritized in the following order:
  const widgetType = (() => {
    if (isExpired) {
      return 'CARD_EXPIRED'
    }

    if (paymentErrorMessage) {
      return 'PAYMENT_ERROR'
    }

    if (isTrialing) {
      return 'TRIAL'
    }

    if (activePermission) {
      return 'PERMISSION'
    }

    return null
  })()

  const shouldRender = () => {
    if (!widgetType) return false

    if (widgetType !== 'PERMISSION') return true

    // Show upgrade widget for feature related to permissions if:
    // 1. On a free plan
    // 2. Plan doesn't have permission to the feature
    // 3. Plan has a limit, consumption is >= 90%, can upgrade to a higher tier
    // 4. Plan has a limit, they are at that limit, cannot upgrade to a higher tier

    if (!onPaidPlan()) return true

    if (!activePermission.enabled) return true

    if (!activePermission.unlimitedUsage) {
      if (shouldShowUpgrades && activePermission.consumedPercentage() >= 90) {
        return true
      }

      return !shouldShowUpgrades && !activePermission.isBelowLimit()
    }

    return false
  }

  if (!shouldRender()) {
    return null
  }

  let title = ''
  let subTitle = ''
  let ctaText = ''
  let status = ''
  let consumedPercentage = 100
  let limitExceeded = false
  let renderCta = true
  let ctaType = 'upgrade'
  let ctaClickHandler = () => history.push('/account/billing')

  switch (widgetType) {
    case 'CARD_EXPIRED':
      title = 'Credit Card Expired'
      subTitle = 'Update your card now'
      ctaText = 'Update your payment info now'
      status = 'urgent'
      ctaType = 'updateBilling'
      break
    case 'PAYMENT_ERROR':
      title = 'Payment Failed'
      subTitle = 'Update your card now'
      ctaText = 'Update your payment info now'
      status = 'urgent'
      ctaType = 'updateBilling'
      break
    case 'TRIAL':
      const consumed = trialPeriodDays - trialDaysRemaining
      const limit = trialPeriodDays
      const lastTrialDay = limit - consumed <= 1

      consumedPercentage = (consumed / limit) * 100
      limitExceeded = consumed >= limit
      title = lastTrialDay
        ? 'Last day of your trial!'
        : "You're on a free trial"
      subTitle = lastTrialDay
        ? '1 day left!'
        : `${limit - consumed}/${limit} days left of ${planType.toLowerCase()}`
      ctaText = 'Upgrade now'
      status = lastTrialDay ? 'urgent' : 'normal'
      break
    case 'PERMISSION':
      ;({
        title,
        subTitle,
        ctaText,
        status,
        consumedPercentage,
        limitExceeded,
        renderCta,
      } = permissionWidgetData(
        activePermission,
        shouldShowUpgrades,
        toggleUpgradeModal
      ))
      break
    default:
      break
  }

  return (
    <UpgradeWidgetContainer status={status} onClick={ctaClickHandler}>
      {renderCta && ctaType === 'upgrade' && (
        <UpgradeButton
          showText={false}
          className="upgrade-button"
          status={status}
        />
      )}
      <ConsumptionContainer>
        <Title variant="body2">{title}</Title>
        <SubTitle variant="body1" status={status}>
          {subTitle}
        </SubTitle>
        <StyledLinearProgress
          variant="determinate"
          value={Math.min(consumedPercentage, 100)}
          color={limitExceeded || status === 'urgent' ? 'error' : 'secondary'}
        />
        <CtaContainer>
          <CtaText variant="body1">{ctaText}</CtaText>
          <ArrowButton
            className="arrow-button"
            status={status}
            renderCta={renderCta}
            onClick={ctaClickHandler}>
            <ArrowLeft />
          </ArrowButton>
        </CtaContainer>
      </ConsumptionContainer>
    </UpgradeWidgetContainer>
  )
}

export default UpgradeWidget

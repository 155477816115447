import { useState, useContext, useRef, useCallback } from 'react'
import {
  Avatar,
  IconButton,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material'
import { Slider, Button } from '@material-ui/core'
import { keyframes, styled } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'
import Cropper from 'react-easy-crop'

import Api from '../../services/api'
import { generateCroppedImage, getNormalizedFile } from '../../utils/cropImage'
import { dataUrlToFileUsingFetch } from '../../utils/dataUrlToFile'
import { AuthenticationContext } from '../authentication/authenticationContext'
import { OnboardingContext } from '../onboarding/onboardingContext'
import EmptyAvatar from '../../icons/emptyAvatar.svg'
import EmptyGroupAvatar from '../../icons/emptyGroupAvatar.svg'

const pulsate = keyframes({
  '0%': {
    boxShadow: 'rgb(255, 153, 0) 0px 0px 1px 1px',
  },
  '50%': {
    boxShadow: 'rgb(255, 153, 0.75) 0px 0px 6px 6px',
  },
  '100%': {
    boxShadow: 'rgb(255, 153, 0) 0px 0px 0px 0px',
  },
})

const CropperDialogContent = styled(DialogContent)({
  position: 'relative',
})

const CloseModalButton = styled(IconButton)({
  position: 'absolute',
  right: 8,
  top: 8,
  color: (theme) => theme.palette.grey[500],
})

const CropperSliderContainer = styled('div')({
  height: '450px',
})

const CropperContainer = styled('div')({
  height: '90%',
  position: 'relative',
})

const SliderContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '24px',
})

const SliderWrapper = styled('div')({
  width: '50%',
})

const AvatarIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'inSpeakerSlide',
})(({ inSpeakerSlide }) => ({
  padding: inSpeakerSlide ? '0px' : '8px',
  marginRight: inSpeakerSlide ? '20px' : '0px',
  animation: inSpeakerSlide
    ? `${pulsate} 1s ease-in-out 0s infinite normal none running`
    : 'none',
}))

const ProfileAvatar = styled(Avatar, {
  shouldForwardProp: (prop) => prop !== 'isLoading' && prop !== 'size',
})(({ isLoading, size }) => ({
  width: size ? `${size}px` : '200px',
  height: size ? `${size}px` : '200px',
  opacity: `${isLoading ? 0.3 : 1}`,
  // Note: the image width and height here is a solution to
  // prevent a small black border to show up on the left and top
  // of the image in certain occassions.
  img: {
    width: '102%',
    height: '102%',
  },
}))

const EditAvatar = ({
  currentAvatar,
  submitOverrideFunction = null,
  size,
  inSpeakerSlide,
  callback,
  isGroupAvatar,
}) => {
  const [avatarFile, setAvatarFile] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [image, setImage] = useState(null)
  const [croppedArea, setCroppedArea] = useState(null)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [cropperOpen, setCropperOpen] = useState(false)

  const { setNotification } = useContext(AuthenticationContext)
  const { submitAvatar } = useContext(OnboardingContext)

  const inputRef = useRef()

  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels)
  }

  const onSave = async () => {
    const croppedImage = await generateCroppedImage(image, croppedArea)
    const file = await dataUrlToFileUsingFetch(
      croppedImage,
      'profile_pic.jpeg',
      'image/jpeg'
    )

    setAvatarFile(croppedImage)

    await editAvatar(file)

    setCropperOpen(false)
    setImage(null)

    if (inputRef?.current) {
      inputRef.current.value = ''
    }

    // if we are in the speaker slide, we need to advance the survey offers onboarding last step
    if (callback) {
      callback()
    }
  }

  const handleChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      const imageDataUrl = await readFile(file)
      setImage(imageDataUrl)
      setCropperOpen(true)
    }
  }

  const clearValue = () => {
    inputRef.current.value = ''
  }

  const handleClose = () => {
    setCropperOpen(false)
  }

  const readFile = useCallback((file) => {
    return new Promise((resolve, reject) => {
      try {
        const reader = new FileReader()
        reader.onload = () => {
          resolve(reader.result)
        }
        getNormalizedFile(file)
          .then((normalizedFile) => reader.readAsDataURL(normalizedFile))
          .catch((error) => reject(error))
      } catch (error) {
        reject(error)
      }
    })
  }, [])

  const sendFileToAmazon = async (presignedUrl, uploadableFile) => {
    const res = await fetch(presignedUrl, {
      method: 'PUT',
      body: uploadableFile,
      headers: {
        'Content-Type': uploadableFile.type,
      },
    })

    if (!res.ok) {
      throw 'Sorry, something went wrong uploading your file'
    }

    return
  }

  const editAvatar = async (uploadableFile) => {
    if (!uploadableFile) {
      return
    }

    if (isLoading) {
      return
    }

    setIsLoading(true)

    let fileParts = uploadableFile.name.split('.')
    let fileName = fileParts[0]
    let fileType = fileParts[1]

    try {
      const params = {
        avatar_upload: {
          file_name: fileName,
          file_type: fileType,
        },
      }

      const res = await Api.presignAvatarUrl(params)
      await sendFileToAmazon(res.presignedUrl, uploadableFile)
      !!submitOverrideFunction
        ? await submitOverrideFunction(res.storageUrl)
        : await submitAvatar(res.storageUrl)
      setIsLoading(false)
      // Note: This is throwing an "can't update state on an unmounted component" error
      // setNotification('Picture Updated!', 'success')
    } catch (err) {
      setIsLoading(false)
      setNotification(JSON.stringify(err), 'error')
    }
  }

  const genericAvatar = isGroupAvatar ? EmptyGroupAvatar : EmptyAvatar
  const avatarImage = currentAvatar ? currentAvatar : genericAvatar

  return (
    <>
      <div className="avatar-upload">
        <div className="avatar-upload__input">
          <input
            type="file"
            onClick={clearValue}
            onChange={handleChange}
            id={isGroupAvatar ? 'group-avatar-upload' : 'upload'}
            accept="image/*"
            style={{ display: 'none' }}
            ref={inputRef}
          />
          <label htmlFor={isGroupAvatar ? 'group-avatar-upload' : 'upload'}>
            <AvatarIconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              inSpeakerSlide={inSpeakerSlide}>
              <ProfileAvatar
                id="avatar"
                src={avatarFile ? avatarFile : avatarImage}
                isLoading={isLoading}
                size={size}
              />
            </AvatarIconButton>
          </label>
          <label htmlFor="avatar" />
        </div>
      </div>
      <Dialog
        open={cropperOpen}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="modal-image-cropper">
        <DialogTitle>
          {isGroupAvatar
            ? 'Upload Survey Profile Photo'
            : 'Upload Profile Photo'}
          <CloseModalButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </CloseModalButton>
        </DialogTitle>
        <CropperDialogContent>
          <CropperSliderContainer>
            <CropperContainer>
              <Cropper
                cropShape="round"
                showGrid={false}
                image={image}
                crop={crop}
                zoom={zoom}
                minZoom={0.5}
                restrictPosition={false}
                aspect={1}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
              />
            </CropperContainer>

            <SliderContainer>
              <SliderWrapper>
                <Slider
                  min={0.5}
                  max={3}
                  step={0.1}
                  value={zoom}
                  onChange={(e, zoom) => setZoom(zoom)}
                />
              </SliderWrapper>
            </SliderContainer>
          </CropperSliderContainer>
          {isLoading && (
            <CircularProgress
              sx={{
                position: 'absolute',
                top: '190px',
                left: '-20px',
                zIndex: '10000',
                color: 'orange',
                marginLeft: '50%',
              }}
            />
          )}
        </CropperDialogContent>
        <DialogActions>
          <Button variant="contained" onClick={onSave} sx={{ color: 'white' }}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default EditAvatar

import {
  SurveyContainer,
  SurveyHeader,
  SurveyActions,
  QuestionContainerParent,
  // QuestionContainer,
  // QuestionContainerInner,
  CodeInputTemplate,
} from '@talkadot/survey-component-library'

const CodeBuilderPreviewPane = ({ currentQuestion, user, code }) => {
  const name = user.first_name + ' ' + user.last_name
  const avatar = user.avatar

  const renderPreviewContent = () => {
    if (currentQuestion.questionType === 'code') {
      return (
        <QuestionContainerParent
          content={`<h1>Hi! &#128075; Thanks for your time!</h1>
      <br />
      <br />
      <div>We promise, this is quick.<div>`}
          contentAlign="center">
          <CodeInputTemplate
            value={code}
            handleChange={() => {}}
            speakerName={name}
            invalidValue={false}
          />
          <SurveyActions
            shouldRenderActions={true}
            disabled={false}
            handleClick={() => {}}
            actionText={"Let's Start!"}
          />
        </QuestionContainerParent>
      )
    }
  }

  return (
    <SurveyContainer>
      <SurveyHeader
        survey={{ speaker: { name, avatar }, percentComplete: 0 }}
      />
      {renderPreviewContent()}
    </SurveyContainer>
  )
}

export default CodeBuilderPreviewPane

import { styled } from '@mui/system'
import { Typography } from '@mui/material'

import { ReactComponent as DragIcon } from '../../../icons/burgerIcon_16x16.svg'
import { ReactComponent as EyeIcon } from '../../../icons/eyeIcon_16x16.svg'

const QuestionItemContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'selected',
})(({ theme, selected }) => ({
  display: 'flex',
  padding: theme.spacing(1.75),
  backgroundColor: selected
    ? theme.palette.neutral.main
    : theme.palette.base.white,
  borderRadius: theme.shape.borderRadius.xxs,
  border: `1px solid ${theme.palette.border.light}`,
  cursor: 'pointer',
}))

const DragHandleContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingRight: theme.spacing(1.75),
  minWidth: '28px',
}))

const StyledDragIcon = styled(DragIcon)(({ theme }) => ({
  cursor: 'grab',
  path: {
    fill: theme.palette.text.disabled,
  },
}))

const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'selected',
})(({ theme, selected }) => ({
  color: selected ? theme.palette.base.white : theme.palette.text.disabled,
  fontWeight: theme.fontWeights.semiBold,
  paddingLeft: theme.spacing(1.75),
  flex: 1,
}))

const DisableIconContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const StyledEyeIcon = styled(EyeIcon)(({ theme }) => ({
  cursor: 'pointer',
  path: {
    stroke: theme.palette.text.disabled,
  },
}))

const CodeBuilderPreviewQuestionItem = ({
  question,
  selected,
  sortable = false,
  canDisable = false,
  setCurrentQuestion,
}) => {
  const handleClick = () => {
    if (!selected) {
      setCurrentQuestion(question)
    }
  }

  return (
    <QuestionItemContainer selected={selected} onClick={handleClick}>
      <DragHandleContainer selected={selected}>
        {sortable && <StyledDragIcon />}
      </DragHandleContainer>
      <StyledTypography variant="body1" selected={selected}>
        {/* TODO: use question values to populate */}
        1. General Setup
      </StyledTypography>
      {canDisable && (
        <DisableIconContainer>
          <StyledEyeIcon />
        </DisableIconContainer>
      )}
    </QuestionItemContainer>
  )
}

export default CodeBuilderPreviewQuestionItem

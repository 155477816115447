import { formatDateWithTimeZone } from '../common/helpers'

export const calcLeadWarmth = (lead) => {
  if (isHotLead(lead)) {
    return 'HOT'
  }

  if (isWarmLead(lead)) {
    return 'WARM'
  }

  if (isColdLead(lead)) {
    return 'COLD'
  }

  return ''
}

// Note:
// This is taken from an answer on stack overflow
// https://stackoverflow.com/questions/8358084/regular-expression-to-reformat-a-us-phone-number-in-javascript
export const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  }
  return null
}

const fullSetOfContactInfo = (lead) => {
  const { email, name, organization, title, phone } = lead

  return email && name && organization && title && phone
}

const partialSetOfContactInfo = (lead) => {
  const { email, name, organization, title, phone } = lead

  return name && email && (phone || organization || title)
}

export const isBookingLead = (leadTypes) => {
  return leadTypes?.join('').includes('BOOK')
}

export const isReferLead = (leadTypes) => {
  return leadTypes?.join('').includes('REFER')
}

export const isOptIn = (leadTypes) => {
  return leadTypes?.join('').includes('OPT_IN')
}

// A hot lead is someone who answered "hot" to the classification questions,
// and filled out ALL of their contact info
export const isHotLead = (lead) => {
  return lead?.leadTypes?.join('').includes('HOT') && fullSetOfContactInfo(lead)
}

// A warm lead is someone who answered "hot" to the classification questions,
// but only filled out SOME of their contact info
export const isWarmLead = (lead) => {
  return (
    lead?.leadTypes?.join('').includes('HOT') && partialSetOfContactInfo(lead)
  )
}

// This is what's logged if the user answers "possibly"
// for "If they want to book the speaker for an event".
// We used to classify this as a warm lead, but for now
// are experimenting with labelling them as cold
// This also is for leads who answered "hot" or "warm" for classification,
// but don't fit the criteria for a hot or warm lead
export const isColdLead = (lead) => {
  return (
    lead?.leadTypes?.join('').includes('WARM') ||
    (lead?.leadTypes.join('').includes('HOT') &&
      !isHotLead(lead) &&
      !isWarmLead(lead))
  )
}

export const allLeads = (leads) => {
  return leads.filter((lead) => !lead.archived)
}

export const referLeads = (leads) => {
  return leads.filter(
    (lead) => lead?.leadTypes.join('').includes('REFER') && !lead.archived
  )
}

export const bookingLeads = (leads) => {
  return leads.filter(
    (lead) => lead?.leadTypes.join('').includes('BOOK') && !lead.archived
  )
}

export const optInLeads = (leads) => {
  return leads.filter(
    (lead) => lead?.leadTypes.join('').includes('OPT_IN') && !lead.archived
  )
}

export const archivedLeads = (leads) => {
  return leads.filter((lead) => lead.archived)
}

export const allLeadsSelected = (selectedLeads, leads) => {
  return (
    leads.length > 0 &&
    ungatedLeads(leads)
      .map((lead) => lead.id)
      .every((leadId) => selectedLeads.includes(leadId))
  )
}

export const ungatedLeads = (leads) => {
  return leads.filter((lead) => !lead.isGated)
}

export const humanizedFollowupFailure = (
  { followupSentAt, followupFailure },
  expandedMsg = false
) => {
  let message

  switch (followupFailure) {
    case 'lead invalid state: (lead.contacted || lead.deleted_at || lead.archived)':
      message = 'Skipped - lead was marked as contacted or archived'
      break
    case 'No bonus':
      message = 'Skipped - survey did not have a bonus'
      break
    case 'at subscription limit':
      message = 'Skipped - subscription limit has been reached'
      break
    case 'invalid email':
      message = 'Skipped - email address is invalid'
      break
    default:
      message = followupSentAt
        ? `Post event attempt on ${formatDateWithTimeZone(
            followupSentAt
          )} failed.  Email was sent but could not be delivered.  `
        : 'Email was sent but could not be delivered.  '
  }

  if (expandedMsg && !message.includes('could not be delivered')) {
    message = `Post event email ${message.toLowerCase()}`
  }

  return message
}

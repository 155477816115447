import { useContext } from 'react'
import UpgradeParent from '../payments/UpgradeParent'
import SubscriptionSourceParent from './SubscriptionSourceParent'
import PageHeader from '../common/PageHeader'
import { AuthenticationContext } from '../authentication/authenticationContext'

const BillingParent = () => {
  const { authData } = useContext(AuthenticationContext)
  const { user } = authData

  return (
    <>
      <PageHeader header="Talkadot Plans & Pricing" />
      {/* only show this section if the speaker is on a paid plan at the moment.
          May need to change this when we handle downgrading / inactive speakers */}
      {!!user.membership.plan?.price > 0 && <SubscriptionSourceParent />}
      <UpgradeParent />
    </>
  )
}

export default BillingParent

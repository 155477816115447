import styled from '@emotion/styled'

export const drawerWidth = 272
export const drawerWidthCollapsed = 88
export const drawerWidthMobile = 56
export const upperNavHeight = 140
export const upperNavHeightMobile = 122

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  height: upperNavHeight,
  ...theme.mixins.toolbar,
}))

import { styled, useTheme } from '@mui/system'
import { Typography, Tooltip } from '@mui/material'

import DisabledBadgeIconWrapper from '../common/DisabledBadgeIconWrapper'

import { ReactComponent as FireIcon } from '../../icons/fireIcon_16x16.svg'
import { ReactComponent as WindIcon } from '../../icons/windIcon_16x16.svg'
import { ReactComponent as EmailIcon } from '../../icons/mailIcon_16x16.svg'
import { ReactComponent as SnowFlakeIcon } from '../../icons/snowFlakeIcon_16x16.svg'

import * as Lead from './helpers'
import { capitalize } from '../../utils/string'

const LeadTypeIconContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
})

const StyledLeadTypeIcon = styled('div', {
  shouldForwardProp: (prop) => prop !== 'color' && prop !== 'svgType',
})(({ color, svgType }) => ({
  display: 'flex',
  alignItems: 'center',
  svg: {
    path: {
      fill: svgType === 'fill' ? color : '',
      stroke: svgType === 'stroke' ? color : '',
    },
  },
}))

const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'expanded',
})(({ expanded }) => ({
  fontWeight: expanded ? '600' : '',
}))

const LeadTypeIcon = ({
  lead,
  expanded = false,
  typographyVariant = 'body1',
}) => {
  const theme = useTheme()

  const leadTypes = lead.leadTypes || []

  let iconsData = []
  let type = ''
  let temperature = ''

  const parseLeadTypes = () => {
    if (lead.placeholder) {
      iconsData.push({
        color: theme.palette.neutral.extraLight,
        icon: <FireIcon />,
        svgType: 'fill',
      })
      type = 'lead'

      return
    }

    const isBookingLead = Lead.isBookingLead(leadTypes)
    const isReferLead = Lead.isReferLead(leadTypes)
    const isOptIn = Lead.isOptIn(leadTypes)
    const isColdLead = Lead.isColdLead(lead)

    if (isBookingLead) {
      type = 'lead'
    } else if (isReferLead) {
      type = 'referral'
    } else if (isOptIn) {
      type = 'email'
    }

    if (Lead.isHotLead(lead)) {
      temperature = 'Hot'

      if (isBookingLead) {
        iconsData.push({
          color: theme.palette.error.semiDark,
          icon: <FireIcon />,
          svgType: 'fill',
          tooltip:
            'Hot Booking Lead - This person indicated they want to book you, have an event in mind, and filled out all of their contact information!',
        })
      }

      if (isReferLead) {
        iconsData.push({
          color: theme.palette.error.semiDark,
          icon: <WindIcon />,
          svgType: 'stroke',
          tooltip:
            'Hot Referral Lead - This person indicated they want to refer you, have an event in mind, and filled out all of their contact information!',
        })
      }
    }

    if (Lead.isWarmLead(lead) && !Lead.isHotLead(lead)) {
      temperature = 'Warm'

      if (isBookingLead) {
        iconsData.push({
          color: theme.palette.primary.main,
          icon: <FireIcon />,
          svgType: 'fill',
          tooltip:
            'Warm Booking Lead - This person indicated they want to book you, have an event in mind, but have only filled out some of their contact information.',
        })
      }

      if (isReferLead) {
        iconsData.push({
          color: theme.palette.primary.main,
          icon: <WindIcon />,
          svgType: 'stroke',
          tooltip:
            'Warm Referral Lead - This person indicated they want to refer you, have an event in mind, but have only filled out some of their contact information.',
        })
      }
    }

    if (isColdLead) {
      temperature = 'Cold'
      let tooltip

      if (isBookingLead) {
        tooltip =
          'Cold Booking Lead - This person indicated they possibly want to book you.'
      }

      if (isReferLead) {
        tooltip =
          'Cold Referral Lead - This person indicated they want to refer you, but did not fill out all of their contact information.'
      }

      iconsData.push({
        color: theme.palette.secondary.main,
        icon: <SnowFlakeIcon />,
        svgType: 'stroke',
        tooltip: tooltip,
      })
    }

    if (isOptIn) {
      iconsData.push({
        icon: <EmailIcon />,
        tooltip: 'Opted in to receive emails',
      })
    }

    if (!isOptIn) {
      iconsData.push({
        icon: <EmailIcon />,
        tooltip: 'No to email opt in',
        disabled: true,
      })
    }
  }

  parseLeadTypes()

  const parseLeadTitle = (type) => {
    switch (type) {
      case 'lead':
        return `${temperature} Booking Lead`
      case 'referral':
        return `${temperature} Referral Lead`
      case 'email':
        return 'Email Opt In'
      default:
        return capitalize(type)
    }
  }

  const title = expanded ? parseLeadTitle(type) : capitalize(type)

  return (
    <LeadTypeIconContainer>
      {iconsData.map((iconData, index) => (
        <Tooltip key={index} title={iconData.tooltip} arrow disableInteractive>
          {iconData.disabled ? (
            <DisabledBadgeIconWrapper>
              <StyledLeadTypeIcon
                color={iconData.color}
                svgType={iconData.svgType}>
                {iconData.icon}
              </StyledLeadTypeIcon>
            </DisabledBadgeIconWrapper>
          ) : (
            <StyledLeadTypeIcon
              color={iconData.color}
              svgType={iconData.svgType}>
              {iconData.icon}
            </StyledLeadTypeIcon>
          )}
        </Tooltip>
      ))}
      <StyledTypography variant={typographyVariant} expanded={expanded}>
        {title}
      </StyledTypography>
    </LeadTypeIconContainer>
  )
}

export default LeadTypeIcon

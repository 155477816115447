import { styled } from '@mui/system'
import { Button } from '@mui/material'

import { ReactComponent as ArrowLeft } from '../../../icons/arrowLeft.svg'

const NavContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'singleButton',
})(({ theme, singleButton }) => ({
  display: 'flex',
  justifyContent: singleButton ? 'flex-end' : 'space-between',
  padding: theme.spacing(3, 0),
}))

const BackButton = styled(Button)(({ theme, disabled }) => ({
  color: theme.palette.base.white,
  svg: {
    marginRight: theme.spacing(1),
    path: {
      stroke: disabled
        ? theme.palette.action.disabled
        : theme.palette.base.white,
    },
  },
  [theme.breakpoints.down('md')]: {
    svg: {
      marginRight: 0,
    },
    '& .button-text': {
      display: 'none',
    },
  },
}))

const NextButton = styled(Button)(({ theme, disabled }) => ({
  color: theme.palette.base.white,
  svg: {
    transform: 'rotate(180deg)',
    marginLeft: theme.spacing(1),
    path: {
      stroke: disabled
        ? theme.palette.action.disabled
        : theme.palette.base.white,
    },
  },
  [theme.breakpoints.down('md')]: {
    svg: {
      marginLeft: 0,
    },
    '& .button-text': {
      display: 'none',
    },
  },
}))

const SkipButton = styled(Button)(({ theme }) => ({
  fontWeight: theme.fontWeights.regular,
}))

const CodeBuilderNavigation = ({
  handleBack,
  handleNext,
  handleSkip,
  showBackButton,
  showBuilderSkipButton,
}) => {
  return (
    <NavContainer singleButton={!showBackButton && !showBuilderSkipButton}>
      {showBackButton && (
        <BackButton variant="contained" onClick={handleBack}>
          <ArrowLeft />
          <span className="button-text">Back</span>
        </BackButton>
      )}
      {showBuilderSkipButton && (
        <SkipButton variant="text" color="secondary" onClick={handleSkip}>
          I want to use all Talkadot defaults. Go straight to add a bonus
        </SkipButton>
      )}
      <NextButton variant="contained" onClick={handleNext}>
        <span className="button-text">Next</span>
        <ArrowLeft />
      </NextButton>
    </NavContainer>
  )
}

export default CodeBuilderNavigation

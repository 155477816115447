import { useState } from 'react'
import { styled } from '@mui/system'
import { Tabs, Tab } from '@mui/material'

import CodeBuilderPreviewQuestionItem from './CodeBuilderPreviewQuestionItem'
import CodeBuilderPreviewPane from './CodeBuilderPreviewPane'

const PreviewContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '336px',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}))

const StyledTabs = styled(Tabs)({
  ' .MuiTabs-flexContainer': {
    alignItems: 'flex-end',
  },
})

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  minWidth: 72,
  fontWeight: '400',
  color: theme.palette.text.disabled,
  marginRight: theme.spacing(0.5),
  height: '52px',
  borderRadius: `${theme.shape.borderRadius.xs} ${theme.shape.borderRadius.xs} 0 0`,
  backgroundColor: theme.palette.base.white,
  '&:last-child': {
    marginRight: 0,
  },
  '&.Mui-selected': {
    color: theme.palette.text.inputText,
    fontWeight: '600',
    border: `1px solid ${theme.palette.border.light}`,
    borderBottom: 'none',
  },
  '&:not(.Mui-selected)': {
    border: `6px solid ${theme.palette.border.light}`,
    height: '45px',
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'rgba(100, 95, 228, 0.32)',
  },
}))

const TabContentContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '600px',
  gap: theme.spacing(0.5),
  padding: theme.spacing(1),
  backgroundColor: theme.palette.base.white,
  borderRadius: `0 0 ${theme.shape.borderRadius.xs} ${theme.shape.borderRadius.xs}`,
  border: `1px solid ${theme.palette.border.light}`,
  borderTop: 'none',
}))

const CodeBuilderPreviewPaneParent = ({
  currentQuestion,
  setCurrentQuestion,
  questions,
  user,
  code,
}) => {
  const [activeTab, setActiveTab] = useState(0)

  const previousQuestion = () => {
    // get index of currentQuestion
    const currentIndex = questions.findIndex(
      (question) => question.id === currentQuestion.id
    )

    const previousQuestionIndex = currentIndex - 1

    if (previousQuestionIndex < 0) {
      return
    }

    return questions[previousQuestionIndex]
  }

  const nextQuestion = () => {
    // get index of currentQuestion
    const currentIndex = questions.findIndex(
      (question) => question.id === currentQuestion.id
    )

    const nextQuestionIndex = currentIndex + 1

    if (nextQuestionIndex >= questions.length) {
      return
    }

    return questions[nextQuestionIndex]
  }

  const renderTabContent = () => {
    if (activeTab === 0) {
      return (
        // TODO: implement this once API is hooked up
        // questions.map((question, index) => (
        //   <CodeBuilderPreviewQuestionItem
        //     key={index}
        //     question={question}
        //     selected={true}
        //     sortable={true}
        //     canDisable={true}
        //   />
        // ))
        <>
          <CodeBuilderPreviewQuestionItem />
          <CodeBuilderPreviewQuestionItem
            sortable={true}
            selected={true}
            canDisable={true}
            setCurrentQuestion={setCurrentQuestion}
          />
        </>
      )
    } else {
      return (
        <>
          <CodeBuilderPreviewQuestionItem selected={true} />
          <CodeBuilderPreviewPane
            currentQuestion={currentQuestion}
            user={user}
            code={code}
          />
          <CodeBuilderPreviewQuestionItem />
        </>
      )
    }
  }

  return (
    <PreviewContainer>
      <StyledTabs
        value={activeTab}
        onChange={(_, newValue) => setActiveTab(newValue)}
        variant="fullWidth"
        TabIndicatorProps={{
          style: { display: 'none' },
        }}>
        <StyledTab label="All Questions" />
        <StyledTab label="Preview Feedback" />
      </StyledTabs>
      <TabContentContainer>{renderTabContent()}</TabContentContainer>
    </PreviewContainer>
  )
}

export default CodeBuilderPreviewPaneParent

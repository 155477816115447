import 'whatwg-fetch'
import * as Cookies from './cookies'
import * as qs from 'qs'
import envConfig from '../envConfig'

const API_ROOT = envConfig.REACT_APP_API_ENDPOINT

const findToken = () => {
  const impersonationToken = Cookies.getImpersonationToken()

  // If an admin user is currently impersonating a user, use this token for requests
  if (impersonationToken) {
    return impersonationToken
  } else {
    return Cookies.getAuthToken()
  }
}

async function makeRequest(path, method, data = {}, hasFile = false) {
  const url = API_ROOT + path

  // NOTE: used for testing Zapier on localhost
  // const url =
  //   'https://6928-2001-569-7628-5300-9cfb-2f90-3451-2da0.ngrok-free.app' + path

  const authToken = findToken()

  const headers = {
    Authorization: `Bearer ${authToken}`,
  }

  if (!hasFile) {
    headers['Content-Type'] = 'application/json'
  }

  try {
    let res

    if (method === 'post' || method === 'put' || method === 'delete') {
      let body
      if (hasFile) {
        body = new FormData()
        body.append('file', data.file)

        // Append other data
        for (const key in data) {
          if (data.hasOwnProperty(key) && key !== 'file') {
            body.append(key, data[key])
          }
        }
      } else {
        body = JSON.stringify(data)
      }

      res = await fetch(url, {
        method,
        headers,
        body: body,
      })
    } else {
      res = await fetch(url, {
        method,
        headers,
      })
    }

    const json = await res.json()

    // If the user is unauthorized or their
    // authentication has expired, delete their
    // old auth token and force them back to the login page
    if (res.status === 401) {
      Cookies.deleteAuthToken()
      return window.location.reload()
    }

    if (res.status === 200) {
      return json.data
    }

    if (res.status === 500) {
      throw json
    }

    return json
  } catch (err) {
    return {
      errors: err,
    }
  }
}

// Modifies the fetch function by: Adding access token and/or changing HTTP method
function get(path, data) {
  return makeRequest(path, 'get', data)
}

function post(path, data, hasFile = false) {
  return makeRequest(path, 'post', data, hasFile)
}

function put(path, data) {
  return makeRequest(path, 'put', data)
}

function destroy(path, data) {
  return makeRequest(path, 'delete', data)
}

const apiEndpoints = {
  registerPaidUser(data) {
    return post('/registrations/paid_registration', data)
  },

  registerUnpaidUser(data) {
    return post('/registrations/unpaid_registration', data)
  },

  loginUser(data) {
    return post('/authentication', data)
  },

  resetPassword(data) {
    return post('/users/reset-password', data)
  },

  requestPasswordReset(data) {
    return post('/password_resets', data)
  },

  resetPasswordbyToken(data) {
    return put(`/password_resets/${data.password_reset.token}`, data)
  },

  loadAuthenticatedUserData() {
    return get('/authentication')
  },

  googleAuth(data) {
    return post('/auth/google', data)
  },

  linkedinAuth(data) {
    return post('/auth/linkedin', data)
  },

  registerWithGoogle(data) {
    return post('/registrations/google', data)
  },

  registerWithLinkedin(data) {
    return post('/registrations/linkedin', data)
  },

  loadSurvey(data) {
    const queryParams = qs.stringify(data, {
      arrayFormat: 'brackets',
      indices: false,
    })
    return get(`/survey?${queryParams}`)
  },

  createAnswer(data) {
    return post('/answers', data)
  },

  updateAnswer(data) {
    return put(`/answers/${data.answer.id}`, data)
  },

  updateUser(data) {
    return put(`/users/${data.user.id}`, data)
  },

  updateReferrer(data) {
    return post('/users/update-referrer', data)
  },

  updateOnboardingInfo(data) {
    return post(`/onboarding_infos`, data)
  },

  submitSocialMediaData(data) {
    return post('/social_media_links', data)
  },

  createSurvey() {
    return post('/surveys', {})
  },

  loadSurveyOffers() {
    return get('/dashboard/survey-link')
  },

  createSurveyOffer(data) {
    return post('/survey_offers', data)
  },

  getAllSurveyOffers() {
    return get('/survey_offers')
  },

  getAllOfferCodes(data) {
    const queryParams = qs.stringify(data, {
      arrayFormat: 'brackets',
      indices: false,
    })

    return get(`/survey_offers/offer_codes?${queryParams}`)
  },

  presignUrl(data) {
    return post('/presign_urls', data)
  },

  presignAvatarUrl(data) {
    return post('/avatar_uploads', data)
  },

  getSocialShareImage(data) {
    const queryParams = qs.stringify(data, {
      arrayFormat: 'brackets',
      indices: false,
    })

    return get(`/download_social_share_image?${queryParams}`)
  },

  deleteSurveyOffer(surveyOfferId) {
    return destroy(`/survey_offers/${surveyOfferId}`)
  },

  editSurveyOffer(data, surveyOfferId) {
    return put(`/survey_offers/${surveyOfferId}`, data)
  },

  getSurveyOfferByCode(data) {
    const queryParams = qs.stringify(data, {
      arrayFormat: 'brackets',
      indices: false,
    })
    return get(`/submissions/complete?${queryParams}`)
  },

  getAnswersBySubmission(data) {
    const queryParams = qs.stringify(data, {
      arrayFormat: 'brackets',
      indices: false,
    })
    return get(`/submissions/show?${queryParams}`)
  },

  loadAllLeads() {
    return get('/leads')
  },

  updateLeads(data) {
    return put(`/leads/bulk-update`, data)
  },

  deleteLeads(data) {
    return destroy(`/leads/bulk-delete`, data)
  },

  fetchLeadData(leadId, data) {
    const queryParams = qs.stringify(data, {
      arrayFormat: 'brackets',
      indices: false,
    })
    return get(`/leads/${leadId}?${queryParams}`)
  },

  createEvent(data) {
    return post('/events', data)
  },

  editEvent(eventId, data) {
    return put(`/events/${eventId}`, data)
  },

  loadEvent(eventId) {
    return get(`/events/${eventId}`)
  },

  deleteEvent(eventId) {
    return destroy(`/events/${eventId}`)
  },

  loadEvents() {
    return get('/events')
  },

  fetchNotifications() {
    return get('/dashboard')
  },

  getFeedbackSummary() {
    return get('/dashboard/audience-feedback')
  },

  getLeadsSummary() {
    return get('/dashboard/leads-summary')
  },

  getLeadsBreakdown() {
    return get('/dashboard/leads-breakdown')
  },

  getEventReportCard(data) {
    const queryParams = qs.stringify(data, {
      arrayFormat: 'brackets',
      indices: false,
    })
    return get(`/event-report-card?${queryParams}`)
  },

  getSpeakerSummary(data) {
    const queryParams = qs.stringify(data, {
      arrayFormat: 'brackets',
      indices: false,
    })
    return get(`/speaker_summaries?${queryParams}`)
  },

  getPublicProfileSettings(data) {
    const queryParams = qs.stringify(data, {
      arrayFormat: 'brackets',
      indices: false,
    })
    return get(`/public_profile_settings?${queryParams}`)
  },

  getPublicProfile(data) {
    const queryParams = qs.stringify(data, {
      arrayFormat: 'brackets',
      indices: false,
    })
    return get(`/public_profile?${queryParams}`)
  },

  getPublicProtectedProfile(data) {
    const queryParams = qs.stringify(data, {
      arrayFormat: 'brackets',
      indices: false,
    })
    return get(`/public_protected_profile?${queryParams}`)
  },

  submitEventReview(data) {
    return post(`/event_reviews`, data)
  },

  getEventReviews(data) {
    const queryParams = qs.stringify(data, {
      arrayFormat: 'brackets',
      indices: false,
    })
    return get(`/event_reviews?${queryParams}`)
  },

  getCustomAnswers(data) {
    const queryParams = qs.stringify(data, {
      arrayFormat: 'brackets',
      indices: false,
    })
    return get(`/event-report-card/custom-answers?${queryParams}`)
  },

  upgradePlan(data) {
    return post('/upgrades', data)
  },

  upgradePaidPlan(data) {
    return put('/upgrades', data)
  },

  getAvailableUpgrades(data) {
    const queryParams = qs.stringify(data, {
      arrayFormat: 'brackets',
      indices: false,
    })

    return get(`/upgrades?${queryParams}`)
  },

  updatePaymentMethod(data, userId) {
    return put(`/user_payment_methods/${userId}`, data)
  },

  getSubscriptionStatus() {
    return get(`/subscription_status`)
  },

  checkShortlinkAvailability(data) {
    const queryParams = qs.stringify(data, {
      arrayFormat: 'brackets',
      indices: false,
    })
    return get(`/shortlink_availabilities?${queryParams}`)
  },

  adminUserList(data) {
    const queryParams = qs.stringify(data, {
      arrayFormat: 'brackets',
      indices: false,
    })

    return get(`/admin/users?${queryParams}`)
  },

  adminGetReferralCommissionList() {
    return get('/admin/referral_commissions')
  },

  adminDeleteSpeaker(speakerId) {
    return destroy(`/admin/users/${speakerId}`)
  },

  adminFetchSpeaker(speakerId) {
    return get(`/admin/users/${speakerId}`)
  },

  markCommissionAsPaid(data) {
    return post('/admin/referral_commissions/mark_as_paid', data)
  },

  uploadTransactions(data) {
    return post('/admin/referral_commissions/upload_transactions', data)
  },

  uploadReferrals(data) {
    return post('/admin/referral_commissions/backfill_referrals', data)
  },

  adminAssociateList() {
    return get('/admin/associates')
  },

  getReferralUserOptions() {
    return get('/admin/referral_data/referral_user_options')
  },

  impersonateUser(data) {
    return post('/admin/impersonations', data)
  },

  getMembershipPlans() {
    return get('/admin/membership_plans')
  },

  updateMembership(data, membershipId) {
    return put(`/admin/memberships/${membershipId}`, data)
  },

  getAvailableMembershipStatusus() {
    return get('/admin/membership_statusus')
  },

  updateAdminUser(data, userId) {
    return put(`/admin/users/${userId}`, data)
  },

  testSpeakerflowIntegration() {
    return post(`/integrations/speakerflow-test`, {})
  },

  createIntegration(data) {
    return post('/integrations', data)
  },

  updateIntegration(data, integrationId) {
    return put(`/integrations/${integrationId}`, data)
  },

  mergeEvent(data) {
    return post(`/event_merges`, data)
  },

  manuallySyncWithSpeakerflow() {
    return get('/integrations/manual-speakerflow-sync')
  },

  uploadEventGroupSchedule(data) {
    return post('/event_planner/event_group_schedules', data)
  },

  fetchEventGroupOverallData(id) {
    return get(`/event_planner/event_group_reports/${id}`)
  },

  getMembershipPlanDetails(data) {
    const queryParams = qs.stringify(data, {
      arrayFormat: 'brackets',
      indices: false,
    })
    return get(`/registrations/plan-details?${queryParams}`)
  },

  getReferrerPlan(data) {
    const queryParams = qs.stringify(data, {
      arrayFormat: 'brackets',
      indices: false,
    })
    return get(`/registration-referrer-plan?${queryParams}`)
  },

  fetchAllEventGroups() {
    return get('/event_planner/event_groups')
  },

  fetchEventGroup(id) {
    return get(`/event_planner/event_groups/${id}`)
  },

  createEventGroup(data) {
    return post(`/event_planner/event_groups`, data)
  },

  searchForSpeakers(data) {
    const queryParams = qs.stringify(data, {
      arrayFormat: 'brackets',
      indices: false,
    })
    return get(`/event_planner/event_speakers/search?${queryParams}`)
  },

  addExistingSpeakerToEvent(data) {
    return post('/event_planner/event_speakers', data)
  },

  removeSpeakerFromEvent(data, speakerId) {
    const queryParams = qs.stringify(data, {
      arrayFormat: 'brackets',
      indices: false,
    })

    return destroy(`/event_planner/event_speakers/${speakerId}?${queryParams}`)
  },

  createNewSpeaker(data) {
    return post('/event_planner/event_speakers/new_speaker', data)
  },

  editEventSpeakerAvatar(data) {
    return put('/event_planner/event_speakers/edit_avatar', data)
  },

  editEventGroup(data, id) {
    return put(`/event_planner/event_groups/${id}`, data)
  },

  assignEventGroupShortlink(id) {
    return put(`/event_planner/assign_shortlink/${id}`)
  },

  unassignEventGroupShortlink(id) {
    return put(`/event_planner/unassign_shortlink/${id}`)
  },

  fetchEventLeads(data) {
    const queryParams = qs.stringify(data, {
      arrayFormat: 'brackets',
      indices: false,
    })

    return get(`/event_leads?${queryParams}`)
  },

  fetchGroupEventLeads(data) {
    const queryParams = qs.stringify(data, {
      arrayFormat: 'brackets',
      indices: false,
    })

    return get(`/group_guest_speakers/event_leads?${queryParams}`)
  },

  syncEventLeadsWithSpeakerflow(data) {
    return post(`/event_leads/sync_with_speakerflow`, data)
  },

  fetchEventRawAnswers(data) {
    const queryParams = qs.stringify(data, {
      arrayFormat: 'brackets',
      indices: false,
    })

    return get(`/event_answers?${queryParams}`)
  },

  fetchGroupEventRawAnswers(data) {
    const queryParams = qs.stringify(data, {
      arrayFormat: 'brackets',
      indices: false,
    })

    return get(`/group_guest_speakers/event_answers?${queryParams}`)
  },

  fetchDefaultEmojiKeywords() {
    return get('/default_emoji_keywords')
  },

  updateProfileSettings(data) {
    return put('/profile_settings', data)
  },

  updateProfile(data) {
    return post('/profiles', data)
  },

  getGroups() {
    return get('/groups')
  },

  getGroupsAggregateEvents() {
    return get('/groups_aggregate_events')
  },

  getGroup(groupId) {
    return get(`/groups/${groupId}`)
  },

  getGroupUsers(groupId) {
    return get(`/groups/${groupId}/users`)
  },

  getGroupEvents(groupId) {
    return get(`/groups/${groupId}/events`)
  },

  createGroup(data) {
    return post('/groups', data)
  },

  updateGroup(groupId, data) {
    return put(`/groups/${groupId}`, data)
  },

  deleteGroup(groupId) {
    return destroy(`/groups/${groupId}`)
  },

  getShareGroups(eventId) {
    return get(`/events/${eventId}/share_groups`)
  },

  getGroupInvitationDetails(data) {
    const queryParams = qs.stringify(data, {
      arrayFormat: 'brackets',
      indices: false,
    })

    return get(`/group_invitations/invitation_details?${queryParams}`)
  },

  getAllPendingInvitations(data) {
    const queryParams = qs.stringify(data, {
      arrayFormat: 'brackets',
      indices: false,
    })

    return get(`/group_invitations?${queryParams}`)
  },

  getUnauthGroupInvitationDetails(data) {
    const queryParams = qs.stringify(data, {
      arrayFormat: 'brackets',
      indices: false,
    })

    return get(`/group_invitations/unauth_invitation_details?${queryParams}`)
  },

  sendGroupInvitations(data) {
    return post('/group_invitations', data)
  },

  createGroupMembership(data) {
    return post('/group_membership', data)
  },

  updateGroupMembership(data) {
    return put(`/update_group_membership`, data)
  },

  deleteGroupMembership(data) {
    return destroy(`/delete_group_membership`, data)
  },

  removeUserFromGroup(data) {
    return destroy(`/remove_group_member`, data)
  },

  updateUserMembership(data) {
    return put(`/update_user_group_membership`, data)
  },

  getAllGroupMembers(data) {
    const queryParams = qs.stringify(data, {
      arrayFormat: 'brackets',
      indices: false,
    })

    return get(`/group_memberships?${queryParams}`)
  },

  getGroupSurveyLink(groupId) {
    return get(`/group_guest_speakers/dashboard/${groupId}/survey-link`)
  },

  createGroupSurveyOffer(data) {
    return post('/group_guest_speakers/survey_offers', data)
  },

  editGroupSurveyOffer(data, surveyOfferId) {
    return put(`/group_guest_speakers/survey_offers/${surveyOfferId}`, data)
  },

  deleteGroupSurveyOffer(surveyOfferId, groupId) {
    const data = { group_id: groupId }

    return destroy(`/group_guest_speakers/survey_offers/${surveyOfferId}`, data)
  },

  getAllGroupOfferCodes(data) {
    const queryParams = qs.stringify(data, {
      arrayFormat: 'brackets',
      indices: false,
    })

    return get(`/group_guest_speakers/survey_offers/offer_codes?${queryParams}`)
  },

  createGroupEvent(data) {
    return post('/group_guest_speakers/events', data)
  },

  editGroupEvent(eventId, data) {
    return put(`/group_guest_speakers/events/${eventId}`, data)
  },

  loadGroupEvent(eventId, data) {
    const queryParams = qs.stringify(data, {
      arrayFormat: 'brackets',
      indices: false,
    })

    return get(`/group_guest_speakers/events/${eventId}?${queryParams}`)
  },

  deleteGroupEvent(eventId, groupId) {
    const data = { group_id: groupId }

    return destroy(`/group_guest_speakers/events/${eventId}`, data)
  },

  loadGroupEvents(data) {
    const queryParams = qs.stringify(data, {
      arrayFormat: 'brackets',
      indices: false,
    })
    return get(`/group_guest_speakers/events?${queryParams}`)
  },

  mergeGroupEvent(data) {
    return post(`/group_guest_speakers/event_merges`, data)
  },

  editGroupEventSpeakerAvatar(data) {
    return put('/group_guest_speakers/event_speakers/edit_avatar', data)
  },

  createGroupEventSpeaker(data) {
    return post('/group_guest_speakers/event_speakers/new_speaker', data)
  },

  removeSpeakerFromGroupEvent(data, speakerId) {
    const queryParams = qs.stringify(data, {
      arrayFormat: 'brackets',
      indices: false,
    })

    return destroy(
      `/group_guest_speakers/event_speakers/${speakerId}?${queryParams}`
    )
  },

  addExistingSpeakerToGroupEvent(data) {
    return post('/group_guest_speakers/event_speakers', data)
  },

  searchForGroupSpeakers(data) {
    const queryParams = qs.stringify(data, {
      arrayFormat: 'brackets',
      indices: false,
    })
    return get(`/group_guest_speakers/event_speakers/search?${queryParams}`)
  },

  updateGroupMetadatum(data) {
    return put('/group_guest_speakers/group_metadata', data)
  },

  createEventPermissions(data) {
    return post(`/create_event_permission`, data)
  },

  deleteEventPermission(data) {
    return destroy(`/delete_event_permission`, data)
  },

  loadAssociates() {
    return get('/associates')
  },

  createAssociate(data) {
    return post('/associates', data)
  },

  deleteAssociate(associateId) {
    return destroy(`/associates/${associateId}`)
  },

  createAuthCode(data) {
    const queryParams = qs.stringify(data, {
      arrayFormat: 'brackets',
      indices: false,
    })

    return get(`/api/v1/oauth/authorize?${queryParams}`)
  },

  fetchEmailCampaigns() {
    return get('/email_campaigns')
  },

  updateEmailCampaign(campaignId, data) {
    return put(`/email_campaigns/${campaignId}`, data)
  },

  uploadChapterCsv(data) {
    return post(`/admin/chapter_uploads/upload`, data, true)
  },

  updateChapters(data) {
    return put(`/admin/chapter_uploads`, data)
  },

  unsubscribeLead(data) {
    return post(`/lead_unsubscribes`, data)
  },

  resubscribeLead(data) {
    return destroy(`/lead_unsubscribes`, data)
  },

  getBonusUrl(data) {
    const queryParams = qs.stringify(data, {
      arrayFormat: 'brackets',
      indices: false,
    })

    return get(`/bonus_url?${queryParams}`)
  },

  updateAccountSettings(data) {
    return put('/account_settings', data)
  },

  getReferralData() {
    return get('/referrals')
  },
}

export default apiEndpoints

import { styled } from '@mui/system'
import { Typography } from '@mui/material'

import CodeBuilderQuestionHeader from './CodeBuilderQuestionHeader'
import CodeBuilderSettingsCode from './CodeBuilderSettingsCode'

const QuestionContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  border: `1px solid ${theme.palette.border.main}`,
  borderRadius: theme.shape.borderRadius.xxs,
}))

const QuestionContainerInner = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: '1.25rem',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}))

const QuestionNumber = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '42px',
  height: '42px',
  padding: '0.625rem',
  marginRight: theme.spacing(3),
  marginBottom: theme.spacing(3),
  backgroundColor: theme.palette.primary.main,
  borderRadius: theme.shape.borderRadius.xs,
  h5: {
    color: theme.palette.base.white,
  },
}))

const CodeBuilderQuestion = ({
  surveyOffer,
  question,
  questionNumber,
  code,
  setCode,
}) => {
  const renderQuestionSettings = () => {
    switch (question.questionType) {
      case 'code':
        return (
          <CodeBuilderSettingsCode
            surveyOffer={surveyOffer}
            code={code}
            setCode={setCode}
          />
        )
      case 'thankyou':
        return <div>Thank you settings</div>
      default:
        return <div>Question settings</div>
    }
  }

  return (
    <QuestionContainer>
      <CodeBuilderQuestionHeader question={question} />
      <QuestionContainerInner>
        <QuestionNumber>
          <Typography variant="h5">{questionNumber}</Typography>
        </QuestionNumber>
        {renderQuestionSettings()}
      </QuestionContainerInner>
    </QuestionContainer>
  )
}

export default CodeBuilderQuestion

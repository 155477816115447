import React, { useState } from 'react'
import {
  Typography,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  Switch,
} from '@mui/material'
import SettingsIcon from '@mui/icons-material/Settings'

const UpdateUserSettings = ({ user, updateUser }) => {
  const [open, setOpen] = useState(false)

  return (
    <div>
      <Tooltip placement="top" title="Edit User Settings">
        <SettingsIcon onClick={() => setOpen(true)} />
      </Tooltip>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={() => setOpen(false)}>
        <DialogTitle>Update User Settings</DialogTitle>
        <DialogContent>
          <div className="user-settings-row">
            <Typography variant="body1">
              Hide Leadgen Questions?{' '}
              <i>(email on first question will still show)</i>
            </Typography>
            <Switch
              onChange={(e) =>
                updateUser(user, {
                  disable_leadgen_questions: e.target.checked,
                })
              }
              checked={user.disableLeadgenQuestions}
            />
          </div>
          <div className="user-settings-row">
            <Typography variant="body1">Hide Bonus Link?</Typography>
            <Switch
              onChange={(e) =>
                updateUser(user, { hide_freebie_link: e.target.checked })
              }
              checked={user.hideFreebieLink}
            />
          </div>
          <div className="user-settings-row">
            <Typography variant="body1">Event Planner Account?</Typography>
            <Switch
              onChange={(e) =>
                updateUser(user, { is_event_planner: e.target.checked })
              }
              checked={user.isEventPlanner}
            />
          </div>
          <div className="user-settings-row">
            <Typography variant="body1">Has Referral Payment Info?</Typography>
            <Switch
              onChange={(e) =>
                updateUser(user, {
                  has_referral_payment_info: e.target.checked,
                })
              }
              checked={user.hasReferralPaymentInfo}
            />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default UpdateUserSettings

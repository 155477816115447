import { useContext, useEffect, useState } from 'react'
import { styled, useTheme } from '@mui/system'
import { useMediaQuery } from '@mui/material'
import { Link } from 'react-router-dom'

import Api from '../../services/api'
import { AuthenticationContext } from '../authentication/authenticationContext'
import { AdminContext } from '../admin/adminContext'

import { ReactComponent as DownloadIcon } from '../../icons/downloadIcon_16x16.svg'
import { ReactComponent as TrashIcon } from '../../icons/trash2Icon_16x16.svg'
import { ReactComponent as ArchiveIcon } from '../../icons/archiveIcon_16x16.svg'
import { ReactComponent as LeadsIcon } from '../../icons/leadsIcon_16x16.svg'
import { ReactComponent as FireIcon } from '../../icons/fireIcon_16x16.svg'
import { ReactComponent as WindIcon } from '../../icons/windIcon_16x16.svg'
import { ReactComponent as EmailIcon } from '../../icons/mailIcon_16x16.svg'

import { usePermissionHelper, LEADS_LIMIT } from '../../utils/permission_helper'
import { exportLeads } from '../../utils/exportCsv'
import { processWithFallback } from '../common/helpers'
import * as Lead from './helpers'

import { GENERIC as UPGRADE_MESSAGE } from '../../constants/messages/upgrade'

import LeadsTabs from './LeadsTabs'
import LeadsTable from './LeadsTable'
import PageHeader from '../common/PageHeader'
import StatsSummaryHeader from '../common/StatsSummaryHeader'
import TableActions from '../common/TableActions'
import ConfirmDialog from '../common/ConfirmDialog'
import { Notification } from '../common/Notification'

const StyledDownloadIcon = styled(DownloadIcon)(({ theme }) => ({
  path: {
    stroke: theme.palette.base.white,
  },
}))

const LeadsParent = () => {
  const [leads, setLeads] = useState([])
  const [allLeads, setAllLeads] = useState([])
  const [loading, setLoading] = useState(true)
  const [activeTab, setActiveTab] = useState('all')
  const [page, setPage] = useState(0)
  const [selectedLeads, setSelectedLeads] = useState([])
  const [confirmOpen, setConfirmOpen] = useState(false)

  const {
    authData: { user },
    setNotification,
    updatePermission,
    updateCounter,
  } = useContext(AuthenticationContext)
  const { adminState } = useContext(AdminContext)
  const { canDownloadLeads } = usePermissionHelper()
  const theme = useTheme()
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'))
  const { findPermission } = usePermissionHelper()
  const leadsPermissionData = findPermission(LEADS_LIMIT)
  const { limit, isBelowLimit, unlimitedUsage } = leadsPermissionData

  useEffect(() => {
    const fetchLeads = async () => {
      try {
        const res = await Api.loadAllLeads()

        if (!res.errors) {
          setAllLeads(res.leads)
          setLeads(res.leads)

          // Update permission values for upgrade widget
          const leadsLimitPermission = res.leadsLimitPermission
          updatePermission(leadsLimitPermission)

          setLoading(false)
        } else {
          throw res.errors
        }
      } catch (err) {
        setLoading(false)
        setNotification(err)
      }
    }

    fetchLeads()
  }, [])

  useEffect(() => {
    switch (activeTab) {
      case 'all':
        setLeads(Lead.allLeads(allLeads))
        break
      case 'booking':
        setLeads(Lead.bookingLeads(allLeads))
        break
      case 'referral':
        setLeads(Lead.referLeads(allLeads))
        break
      case 'email':
        setLeads(Lead.optInLeads(allLeads))
        break
      case 'archived':
        setLeads(Lead.archivedLeads(allLeads))
        break
      default:
        setLeads(allLeads)
    }
  }, [activeTab, allLeads])

  useEffect(() => {
    if (!loading) {
      const updatedCount = allLeads.filter((event) => !event.viewed).length

      updateCounter({ unviewedLeads: updatedCount })
    }
  }, [allLeads, loading])

  // filter selected leads to only include selected leads that correspond to the current tab
  const filteredSelectedLeads = selectedLeads.filter((leadId) =>
    leads.find((lead) => lead.id === leadId)
  )

  const handleUpdateLeads = async (
    leadsToUpdate,
    updateParams,
    clearSelected = false,
    suppressNotification = false
  ) => {
    try {
      const params = {
        lead: {
          ids: leadsToUpdate,
          ...updateParams,
        },
      }

      const res = await Api.updateLeads(params)

      if (!res.errors) {
        !suppressNotification && setNotification('Lead Updated!', 'success')
        setAllLeads(
          allLeads.map((lead) =>
            leadsToUpdate.includes(lead.id)
              ? { ...lead, ...updateParams }
              : { ...lead }
          )
        )
        clearSelected && setSelectedLeads([])
      } else {
        throw res.errors
      }
    } catch (err) {
      setNotification(err, 'error')
    }
  }

  const confirmDeleteLeads = async () => {
    try {
      const params = {
        lead: {
          ids: filteredSelectedLeads,
        },
      }

      const res = await Api.deleteLeads(params)

      if (!res.errors) {
        setNotification('Lead Deleted!', 'success')
        setAllLeads(allLeads.filter((lead) => !selectedLeads.includes(lead.id)))
        setSelectedLeads([])
      } else {
        throw res.errors
      }
    } catch (err) {
      setNotification(err, 'error')
      setSelectedLeads([])
    }
  }

  const massMarkAsViewed = async (viewedLeads) => {
    const leadsToUpdate = viewedLeads.filter((lead) => !lead.viewed)

    if (leadsToUpdate.length === 0) return

    const leadIds = leadsToUpdate.map((lead) => lead.id)

    await handleUpdateLeads(leadIds, { viewed: true }, false, true)
  }

  const cancelDeleteLeads = () => {
    setSelectedLeads([])
  }

  const handleDeleteLead = (leadId) => {
    if (leadId) {
      const idsToDelete = [leadId]

      setSelectedLeads(idsToDelete)
      setConfirmOpen(true)
    }
  }

  const handleCheckboxChange = (leadId) => {
    if (selectedLeads.includes(leadId)) {
      setSelectedLeads(selectedLeads.filter((id) => id !== leadId))
    } else {
      setSelectedLeads([...selectedLeads, leadId])
    }
  }

  const handleSelectAllChange = () => {
    if (selectedLeads.length === Lead.ungatedLeads(leads).length) {
      setSelectedLeads([])
    } else {
      setSelectedLeads(Lead.ungatedLeads(leads).map((lead) => lead.id))
    }
  }

  const handleDownloadSelectedLeads = async () => {
    const selectedUngatedLeads = leads
      .filter((lead) => selectedLeads.includes(lead.id))
      .filter((lead) => !lead.isGated)

    exportLeads(selectedUngatedLeads)

    // clear selected leads on current tab
    setSelectedLeads(
      selectedLeads.filter(
        (leadId) => !leads.map((lead) => lead.id).includes(leadId)
      )
    )

    if (adminState?.impersonation?.isActive) return

    massMarkAsViewed(selectedUngatedLeads)
  }

  // No api call necessary, lead is marked as viewed during the get request
  const handleMarkAsViewed = (lead) => {
    if (lead.viewed || adminState?.impersonation?.isActive) return

    const updatedLeads = allLeads.map((l) => {
      if (l.id === lead.id) {
        return { ...l, viewed: true }
      }
      return l
    })

    setAllLeads(updatedLeads)
  }

  const statsSummaryItems = [
    {
      label: 'All Types',
      value: processWithFallback(allLeads?.length),
      icon: <LeadsIcon />,
      svgType: 'fill',
      useErrorFontColor: !unlimitedUsage && !isBelowLimit(),
    },
    {
      label: 'Total Leads',
      value: processWithFallback(Lead.bookingLeads(allLeads)?.length),
      icon: <FireIcon />,
      svgType: 'fill',
    },
    {
      label: 'Total Referrals',
      value: processWithFallback(Lead.referLeads(allLeads)?.length),
      icon: <WindIcon />,
      svgType: 'stroke',
    },
    {
      label: 'Total Emails',
      value: processWithFallback(Lead.optInLeads(allLeads)?.length),
      icon: <EmailIcon />,
      svgType: 'stroke',
    },
  ]

  const bulkArchiveHandler = () => {
    if (activeTab === 'archived') {
      return () =>
        handleUpdateLeads(filteredSelectedLeads, { archived: false }, true)
    }

    return () =>
      handleUpdateLeads(
        filteredSelectedLeads,
        { archived: true, viewed: true },
        true
      )
  }

  const bulkArchiveText = () => {
    if (activeTab === 'archived') {
      return 'Unarchive Selected'
    }

    return 'Archive Selected'
  }

  const bulkActions = [
    {
      text: isMediumScreen ? 'Delete' : 'Delete Selected',
      icon: <TrashIcon />,
      onClick: () => setConfirmOpen(true),
      disabled: !filteredSelectedLeads?.length,
    },
    {
      text: isMediumScreen
        ? bulkArchiveText().split(' ')[0]
        : bulkArchiveText(),
      icon: <ArchiveIcon />,
      onClick: bulkArchiveHandler(),
      disabled: !filteredSelectedLeads?.length,
    },
    {
      text: isMediumScreen ? 'Download' : 'Download Selected',
      icon: <StyledDownloadIcon />,
      onClick: handleDownloadSelectedLeads,
      tooltipText: !canDownloadLeads() && UPGRADE_MESSAGE,
      disabled: !canDownloadLeads() || !filteredSelectedLeads?.length,
    },
  ]

  return (
    <>
      <PageHeader
        header="Your Contacts, Leads, and Email Addresses"
        showUpgradeButton={user?.membership?.shouldShowUpgrades}
      />
      {!loading && !unlimitedUsage && (
        <>
          <Notification variant="redWarning">
            You are limited to {limit} contacts of any type on your plan.{' '}
            <Link to="/account/billing">Upgrade</Link> to unlock more contacts.
          </Notification>
          {!isBelowLimit() && (
            <Notification variant="greyWarning">
              Your old contacts are still available. Just scroll past your new
              contacts to see them.
            </Notification>
          )}
        </>
      )}
      <StatsSummaryHeader statItems={statsSummaryItems} />
      <LeadsTabs setActiveTab={setActiveTab} setPage={setPage} />
      <TableActions
        showCta={false}
        showBulkActions={true}
        bulkActions={bulkActions}
        handleSelectAllChange={handleSelectAllChange}
        selectedItems={filteredSelectedLeads}
        allSelected={Lead.allLeadsSelected(selectedLeads, leads)}
      />
      <LeadsTable
        leads={leads}
        loading={loading}
        handleUpdateLeads={handleUpdateLeads}
        handleDeleteLead={handleDeleteLead}
        handleCheckboxChange={handleCheckboxChange}
        handleMarkAsViewed={handleMarkAsViewed}
        selectedLeads={selectedLeads}
        page={page}
        setPage={setPage}
      />
      <ConfirmDialog
        title={'Delete Lead' + (selectedLeads.length > 1 ? 's' : '') + '?'}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={confirmDeleteLeads}
        onCancel={cancelDeleteLeads}
        buttonText="Delete">
        <span>
          {'Are you sure you want to delete' +
            (selectedLeads.length > 1 ? ' these leads' : ' this lead') +
            '?'}
        </span>
      </ConfirmDialog>
    </>
  )
}

export default LeadsParent

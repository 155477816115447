import { styled } from '@mui/system'
import { Typography } from '@mui/material'

const TitleContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}))

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: theme.fontWeights.semiBold,
}))

const CodeBuilderSettingsTitle = ({ title, subTitle }) => {
  return (
    <TitleContainer>
      <Title variant="body2">{title}</Title>
      {subTitle && <Typography variant="body1">{subTitle}</Typography>}
    </TitleContainer>
  )
}

export default CodeBuilderSettingsTitle

import { useContext } from 'react'

import { styled } from '@mui/system'
import { Switch, Typography } from '@mui/material'

import { AuthenticationContext } from '../authentication/authenticationContext'
import {
  usePermissionHelper,
  INTEGRATIONS,
} from '../../utils/permission_helper'

import EditDetails from './EditDetails'
import TestSpeakerflowButton from './TestSpeakerflowButton'
import ManuallySyncSpeakerflowButton from './ManuallySyncSpeakerflowButton'

import UpgradeModalLauncher from '../common/UpgradeModalLauncher'
import UserAttributeForm from '../onboarding/UserAttributeForm'

import {
  AccountSettingsCard,
  AccountSettingsCardColumn,
  AccountSettingsCardInner,
  AccountSettingsCardTitle,
} from './AccountSettingsParent'

import speakerflowLogo from '../../icons/speakerflow.png'

const SpeakerflowContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  gap: '10px',
  justifyContent: 'space-between',
  img: {
    maxWidth: '250px',
  },
  button: {
    marginLeft: '5px',
  },
}))

const SpeakerflowEditContainer = styled('div')({
  width: '100%',
  textAlign: 'left',
  marginLeft: '0px',
  marginRight: '0px',
})

const IntegrationSettings = () => {
  const { authData, submitSpeakerflowLink, toggleSpeakerflowEnabled } =
    useContext(AuthenticationContext)
  const { user } = authData
  const { canUseIntegrations } = usePermissionHelper()
  const isIntegrationsAllowed = canUseIntegrations()

  const renderShowSpeakerflowLink = () => {
    const disableButtons =
      !isIntegrationsAllowed ||
      !user.speakerflow_url ||
      user.speakerflow_url.length <= 0

    if (isIntegrationsAllowed) {
      return (
        <SpeakerflowContainer>
          <SpeakerflowEditContainer>
            <EditDetails
              handleUpdate={() => {
                submitSpeakerflowLink(isIntegrationsAllowed)
              }}
              detailType="Speakerflow"
              detailValue={user?.speakerflow_url}>
              <UserAttributeForm
                formValue={user ? user.speakerflow_url : ''}
                formLabel="speakerflow link - keep blank if you don't want to use this feature"
                profileAttribute={'speakerflow_url'}
              />
            </EditDetails>
          </SpeakerflowEditContainer>
          <div>
            <Switch
              onChange={(e) =>
                toggleSpeakerflowEnabled(
                  e.target.checked,
                  isIntegrationsAllowed
                )
              }
              checked={user.speakerflowEnabled}
              disabled={disableButtons}
            />
            <TestSpeakerflowButton disabled={disableButtons} />
            <ManuallySyncSpeakerflowButton disabled={disableButtons} />
          </div>
        </SpeakerflowContainer>
      )
    } else {
      return (
        <SpeakerflowContainer>
          <SpeakerflowEditContainer>
            <img
              className="integration-image"
              src={speakerflowLogo}
              alt="Speakerflow"
            />
            <Typography sx={{ flex: '0.9' }} variant="body1" component="div">
              Integrate your leads with Speakerflow!
              <UpgradeModalLauncher
                content="Upgrade To Add"
                variant="outlined"
                upgradeHelperText="Upgrade To Integrate Your Leads With Speakerflow!"
                permissionType={INTEGRATIONS}
              />
            </Typography>
          </SpeakerflowEditContainer>
        </SpeakerflowContainer>
      )
    }
  }

  return (
    <AccountSettingsCard>
      <AccountSettingsCardTitle variant="h5">
        Speakerflow Integration
      </AccountSettingsCardTitle>
      <AccountSettingsCardInner>
        <AccountSettingsCardColumn>
          {renderShowSpeakerflowLink()}
        </AccountSettingsCardColumn>
      </AccountSettingsCardInner>
    </AccountSettingsCard>
  )
}

export default IntegrationSettings

import { useContext } from 'react'
import { AuthenticationContext } from '../features/authentication/authenticationContext'
import { GroupContext } from '../features/groups/groupContext'

// These constants should match the Permission Model in the backend
export const LEADS_LIMIT = 'LEADS_LIMIT'
export const LEAD_DOWNLOADS = 'LEAD_DOWNLOADS'
export const OFFER_CODE_LIMIT = 'OFFER_CODE_LIMIT'
export const NO_TALKADOT_BRANDING = 'NO_TALKADOT_BRANDING'
export const CUSTOMIZATIONS = 'CUSTOMIZATIONS'
export const EMAIL_CAMPAIGNS = 'EMAIL_CAMPAIGNS'
export const TEAMS = 'TEAMS'
export const INTEGRATIONS = 'INTEGRATIONS'

const stateLoadingPermission = {
  enabled: false,
  limit: 0,
  consumed: 0,
  unlimitedUsage: false,
}

const extendPermission = (permission) => {
  return {
    ...permission,
    consumedPercentage: () => {
      if (!permission.enabled) return 100

      if (permission.unlimitedUsage) {
        return 0
      }

      return Math.min((permission.consumed / permission.limit) * 100, 100)
    },
    isBelowLimit: () => {
      return permission.enabled ? permission.consumed < permission.limit : false
    },
  }
}

export function usePermissionHelper() {
  const {
    authData: {
      user: {
        isAssociate,
        membership: {
          plan: { price },
          permissions,
        },
      },
    },
  } = useContext(AuthenticationContext)
  const {
    groupState: { selectedGroup },
  } = useContext(GroupContext)

  const canToggleTalkadotBranding = () => {
    return findPermission(NO_TALKADOT_BRANDING).enabled
  }

  const canAddCustomQuestion = () => {
    return findPermission(CUSTOMIZATIONS).enabled
  }

  const canUseIntegrations = () => {
    return findPermission(INTEGRATIONS).enabled
  }

  const canDownloadLeads = () => {
    return findPermission(LEAD_DOWNLOADS).enabled
  }

  const canDownloadEventLeads = () => {
    // Additional gate of requiring a paid plan (Lite plans cannot use it)
    return (
      _isUsingChapterFeature() ||
      (onPaidPlan() && findPermission(LEAD_DOWNLOADS).enabled)
    )
  }

  const canUseEmailCampaigns = () => {
    return findPermission(EMAIL_CAMPAIGNS).enabled
  }

  const canCreateSurveyOffer = (currentOfferCount) => {
    const permission = findPermission(OFFER_CODE_LIMIT)

    return permission.unlimitedUsage || permission.limit > currentOfferCount
  }

  const canAccessTeams = () => {
    const permission = findPermission(TEAMS)

    return permission.enabled && !isAssociate
  }

  const findPermission = (permissionType) => {
    const permission = permissions.find(
      (permission) => permission.type === permissionType
    )

    return _buildPermission(permission)
  }

  const _isUsingChapterFeature = () => {
    return !!selectedGroup
  }

  const onPaidPlan = () => {
    return price != null && price > 0
  }

  const _buildPermission = (permission) => {
    // If the permission doesn't exist the authContext is being loaded.
    // Load a default permission state so we don't need try operators everywhere
    if (!permission) {
      return extendPermission(stateLoadingPermission)
    }

    // Override some values if they are using chapters
    let builtPermission = permission
    switch (permission.type) {
      case OFFER_CODE_LIMIT:
        builtPermission = {
          ...permission,
          unlimitedUsage: _isUsingChapterFeature() || permission.unlimitedUsage,
        }
        break
      case CUSTOMIZATIONS:
        builtPermission = {
          ...permission,
          enabled: _isUsingChapterFeature() || permission.enabled,
        }
        break
      default:
        builtPermission = permission
    }

    return extendPermission(builtPermission)
  }

  return {
    canToggleTalkadotBranding,
    canUseIntegrations,
    canAddCustomQuestion,
    canDownloadLeads,
    canDownloadEventLeads,
    canUseEmailCampaigns,
    canCreateSurveyOffer,
    canAccessTeams,
    findPermission,
    onPaidPlan,
  }
}

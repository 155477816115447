import React, { createContext, useReducer, useContext } from 'react'
import { adminReducer, initialAdminState } from './adminReducer'
import { GroupContext } from '../groups/groupContext'
import { AuthenticationContext } from '../authentication/authenticationContext'
import history from '../../routes/history'
import Api from '../../services/api'
import * as Cookies from '../../services/cookies'
import * as ActionTypes from './actionTypes'

export const AdminContext = createContext({
  adminState: initialAdminState,
})

const AdminContextProvider = (props) => {
  const [adminState, dispatch] = useReducer(adminReducer, initialAdminState)
  const {
    showFullPageLoader,
    hideFullPageLoader,
    setUserData,
    redirectUser,
    setNotification,
  } = useContext(AuthenticationContext)
  const { setGroups } = useContext(GroupContext)

  const fetchUsers = async (searchTerm) => {
    if (!searchTerm?.length > 0) {
      return dispatch({
        type: ActionTypes.LOAD_USERS,
        payload: [],
      })
    }

    try {
      const res = await Api.adminUserList({ search_term: searchTerm })

      if (!res.errors) {
        dispatch({
          type: ActionTypes.LOAD_USERS,
          payload: res,
        })
      } else {
        throw res.errors
      }
    } catch (err) {
      alert(JSON.stringify(err))
    }
  }

  const updateUser = async (user, updateParams) => {
    if (!user || !updateParams) {
      return
    }

    const params = {
      user: {
        ...updateParams,
      },
    }

    try {
      const res = await Api.updateAdminUser(params, user.id)

      if (!res.errors) {
        dispatch({
          type: ActionTypes.UPDATE_USER,
          payload: res,
        })
        setNotification(
          `${user.first_name} ${user.last_name} successfully updated!`,
          'success'
        )
      }
    } catch (err) {
      setNotification(err)
    }
  }

  const updateMembership = async (updateParams, user) => {
    try {
      showFullPageLoader()

      const res = await Api.updateMembership(
        { membership: updateParams },
        user.membership.id
      )

      if (!res.errors) {
        dispatch({
          type: ActionTypes.UPDATE_USER,
          payload: res,
        })
        hideFullPageLoader()
        setNotification(
          `${user.first_name} ${user.last_name} successfully updated!`,
          'success'
        )
      } else {
        throw res.errors
      }
    } catch (err) {
      hideFullPageLoader()
      setNotification(err)
    }
  }

  const stopImpersonatingUser = async () => {
    Cookies.deleteImpersonationToken()

    try {
      const res = await Api.loadAuthenticatedUserData()

      if (!res.errors) {
        setUserData(res.userData)
        setGroups(res.groupData)
        dispatch({
          type: ActionTypes.STOP_IMPERSONATION,
        })
      }
    } catch (err) {
      alert(JSON.stringify(err))
    }

    return history.push('/admin')
  }

  const impersonateUser = async (user) => {
    const params = {
      impersonation: {
        impersonation_user_id: user.id,
      },
    }

    try {
      showFullPageLoader('Hang tight ... ')
      const res = await Api.impersonateUser(params)

      if (!res.errors) {
        Cookies.saveImpersonationToken(res.impersonationToken)
        dispatch({
          type: ActionTypes.IMPERSONATE_USER,
          payload: res.user,
        })
        setGroups(res.groupData)
        setUserData(res.user)
        hideFullPageLoader()
        return redirectUser(res.user)
      } else {
        throw res.errors
      }
    } catch (err) {
      hideFullPageLoader()
      return alert(JSON.stringify(err))
    }
  }

  const fetchReferralUserOptions = async () => {
    try {
      const res = await Api.getReferralUserOptions()

      if (!res.errors) {
        dispatch({
          type: ActionTypes.SET_REFERRER_USER_OPTIONS,
          payload: res,
        })
      } else {
        throw res.errors
      }
    } catch (err) {
      alert(
        "Something went wrong loading the referral options.  Try refreshing the page - if that doesn't work, ask the devs!"
      )
    }
  }

  const fetchAvailableMembershipPlans = async () => {
    try {
      showFullPageLoader()
      const res = await Api.getMembershipPlans()

      if (!res.errors) {
        dispatch({
          type: ActionTypes.LOAD_AVAILABLE_MEMBERSHIP_PLANS,
          payload: res,
        })
        return hideFullPageLoader()
      } else {
        throw res.errors
      }
    } catch (err) {
      hideFullPageLoader()
      return alert(JSON.stringify(err))
    }
  }

  const fetchAvailableMembershipStatusus = async () => {
    try {
      showFullPageLoader()
      const res = await Api.getAvailableMembershipStatusus()

      if (!res.errors) {
        dispatch({
          type: ActionTypes.LOAD_AVAILABLE_MEMBERSHIP_STATUSUS,
          payload: res.statusus,
        })
        return hideFullPageLoader()
      } else {
        throw res.errors
      }
    } catch (err) {
      hideFullPageLoader()
      return alert(JSON.stringify(err))
    }
  }

  const deleteSpeaker = async (speakerIdToDelete) => {
    try {
      showFullPageLoader()
      const res = await Api.adminDeleteSpeaker(speakerIdToDelete)

      if (!res.errors) {
        dispatch({
          type: ActionTypes.LOAD_USERS,
          payload: adminState.users.filter(
            (user) => user.id !== speakerIdToDelete
          ),
        })
        hideFullPageLoader()
        alert('Speaker has been successfully deleted!')
      } else {
        throw res.errors
      }
    } catch (err) {
      hideFullPageLoader()
      alert(JSON.stringify(err))
    }
  }

  return (
    <AdminContext.Provider
      value={{
        adminState,
        fetchUsers,
        stopImpersonatingUser,
        impersonateUser,
        fetchAvailableMembershipPlans,
        fetchAvailableMembershipStatusus,
        updateMembership,
        updateUser,
        deleteSpeaker,
        fetchReferralUserOptions,
      }}>
      {props.children}
    </AdminContext.Provider>
  )
}

export default AdminContextProvider

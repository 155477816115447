import * as ActionTypes from './actionTypes'

export const initialAuthState = {
  // I know this is hacky ...
  // Registration Info
  tosChecked: false,
  privacyChecked: false,
  // User Info
  user: {
    id: null,
    email: null,
    first_name: null,
    last_name: null,
    phone_number: null,
    booking_link: null,
    speakerflow_url: null,
    speakerflowEnabled: false,
    isCircleMember: false,
    isAssociate: false,
    avatar: null,
    referrer: null,
    onboarding_complete: null,
    onboarding_info: {
      viewed_welcome_video: null,
      viewed_upgrade_info: null,
      survey_offers: {
        last_step: null,
        completed: null,
      },
    },
    lastOnboardingStep: null,
    shortlink: null,
    showTalkadotBranding: false,
    showUpgradeBanner: false,
    autoSplitEvents: false,
    isAdmin: false,
    isEventPlanner: false,
    hasGroupsFeatureFlag: false,
    canCreateGroup: false,
    socialLinks: {
      instagram: null,
      twitter: null,
      youtube: null,
      linkedin: null,
    },
    currentPassword: null,
    newPassword: null,
    membership: {
      plan: {
        name: '',
        planType: '',
        trialPeriodDays: null,
      },
      status: null,
      permissions: [],
      shouldShowUpgrades: false,
      isTrialing: false,
      subscribedWhileTrialing: false,
      trialDaysRemaining: null,
      nextBillingDate: null,
    },
    billingInfo: {
      brand: null,
      expMonth: null,
      expYear: null,
      last4: null,
      isExpired: false,
      paymentErrorMessage: null,
    },
    // TODO:
    // When we add email invitations, fill this in with more data other
    // than the one they clicked when they authenticated
    // [{ groupName: string, invitationToken: string}]
    groupInvitations: [],
    counters: {
      unviewedEvents: 0,
      unviewedLeads: 0,
    },
  },
  signUpDate: null,
  // Global Info
  fullPageLoader: {
    isActive: false,
    content: null,
    loaderType: null,
  },
  notification: {
    message: null,
    notificationType: 'info',
    showNotification: false,
  },
  showUpgradeModal: false,
  upgradeHelperText: '',
  upgradePermissiontype: null,
}

export const authenticationReducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.SET_USER_ATTRIBUTE:
      return {
        ...state,
        user: {
          ...state.user,
          [action.payload.attributeType]: action.payload.value,
        },
      }
    case ActionTypes.SET_SOCIAL_MEDIA_LINK:
      return {
        ...state,
        user: {
          ...state.user,
          socialLinks: {
            ...state.user.socialLinks,
            [action.payload.attributeType]: action.payload.value,
          },
        },
      }
    case ActionTypes.SET_AUTH_DATA:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      }
    case ActionTypes.ADD_INDUSTRY:
      return {
        ...state,
        user: {
          ...state.user,
          industries: [...state.user.industries, action.payload],
        },
      }
    case ActionTypes.REMOVE_INDUSTRY:
      return {
        ...state,
        user: {
          ...state.user,
          industries: state.user.industries.filter(
            (industry) => industry !== action.payload
          ),
        },
      }
    case ActionTypes.TOGGLE_TOS:
      return {
        ...state,
        tosChecked: !state.tosChecked,
      }
    case ActionTypes.TOGGLE_PRIVACY:
      return {
        ...state,
        privacyChecked: !state.privacyChecked,
      }
    case ActionTypes.RESET_AUTH_DATA:
      return {
        ...initialAuthState,
      }
    case ActionTypes.SHOW_FULL_PAGE_LOADER:
      return {
        ...state,
        fullPageLoader: {
          ...state.fullPageLoader,
          isActive: true,
          content: action.payload.content,
          loaderType: action.payload.loaderType,
        },
      }
    case ActionTypes.HIDE_FULL_PAGE_LOADER:
      return {
        ...state,
        fullPageLoader: {
          ...state.fullPageLoader,
          isActive: false,
          content: null,
        },
      }
    case ActionTypes.TOGGLE_UPGRADE_MODAL:
      return {
        ...state,
        showUpgradeModal: action.payload.showModal,
        upgradePermissiontype: action.payload.permissionType,
        upgradeHelperText: action.payload.helperText,
      }
    case ActionTypes.SET_NOTIFICATION:
      return {
        ...state,
        notification: {
          ...state.notification,
          ...action.payload,
        },
      }
    case ActionTypes.TOGGLE_IMPERSONATING:
      return {
        ...state,
        impersonating: action.payload,
      }
    case ActionTypes.SET_GROUP_INVITATIONS:
      return {
        ...state,
        user: {
          ...state.user,
          groupInvitations: [...state.user.groupInvitations, action.payload],
        },
      }
    case ActionTypes.REMOVE_GROUP_INVITATION:
      return {
        ...state,
        user: {
          ...state.user,
          groupInvitations: state.user.groupInvitations.filter((invitation) => {
            return invitation.id !== action.payload.id
          }),
        },
      }
    case ActionTypes.SET_USER_MEMBERSHIP:
      return {
        ...state,
        user: {
          ...state.user,
          membership: {
            ...state.user.membership,
            ...action.payload,
          },
        },
      }
    case ActionTypes.SET_PERMISSION:
      return {
        ...state,
        user: {
          ...state.user,
          membership: {
            ...state.user.membership,
            permissions: state.user.membership.permissions.map((permission) => {
              if (permission.type === action.payload.type) {
                return action.payload
              }
              return permission
            }),
          },
        },
      }
    case ActionTypes.UPDATE_COUNTER:
      return {
        ...state,
        user: {
          ...state.user,
          counters: {
            ...state.user.counters,
            ...action.payload,
          },
        },
      }
    default:
      return { ...state }
  }
}

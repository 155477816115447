import { useState, useContext } from 'react'
import { styled } from '@mui/system'
import { AppBar, Toolbar, Button, Avatar, Box, Typography } from '@mui/material'

import { ReactComponent as CaretDown } from '../../icons/caretDown_16x16.svg'

import NewSettingsMenu from './NewSettingsMenu'
import UpperNavUnAuth from './UpperNavUnAuth'
import ImpersonationBanner from '../admin/ImpersonationBanner'

import {
  drawerWidth,
  drawerWidthCollapsed,
  drawerWidthMobile,
  upperNavHeight,
  upperNavHeightMobile,
} from '../../styles/layoutNavConstants'

import { GroupContext } from '../groups/groupContext'
import { AdminContext } from '../admin/adminContext'

const StyledAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'loggedInUser',
})(({ theme, open, loggedInUser }) => ({
  justifyContent: 'center',
  backgroundColor: theme.palette.base.white,
  marginLeft: open ? drawerWidth : drawerWidthCollapsed,
  height: upperNavHeight,
  width: loggedInUser
    ? open
      ? `calc(100% - ${drawerWidth}px)`
      : `calc(100% - ${drawerWidthCollapsed}px)`
    : '100%',
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  [theme.breakpoints.down('sm')]: {
    marginLeft: open ? drawerWidth : drawerWidthMobile,
    height: upperNavHeightMobile,
    width: loggedInUser
      ? open
        ? `calc(100% - ${drawerWidth}px)`
        : `calc(100% - ${drawerWidthMobile}px)`
      : '100%',
  },
}))

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  '&.MuiToolbar-gutters': {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}))

const BannerToolbar = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

const RightNavContainer = styled(Button)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: 'pointer',
  position: 'relative',
  minWidth: '258px',
  maxWidth: '400px',
  [theme.breakpoints.down('sm')]: {
    minWidth: '0',
  },
}))

const CaretContainer = styled('div')(({ theme, dropDownOpen }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '15px',
  fontSize: '15px',
  color: theme.palette.nav.expandIcon,
  transform: dropDownOpen ? 'rotate(180deg)' : 'rotate(0deg)',
  transition: 'transform 0.2s ease-in-out',
}))

const ProfileInfoContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontFamily: 'Figtree, sans-serif',
  paddingRight: theme.spacing(2),
}))

const StyledAvatar = styled(Avatar)({
  // Note: the image width and height here is a solution to
  // prevent a small black border to show up on the left and top
  // of the image in certain occassions.
  width: 40,
  height: 40,
  img: {
    width: '102%',
    height: '102%',
  },
})

const AvatarContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginRight: theme.spacing(1),
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    marginRight: 0,
  },
}))

const AvatarNameContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
})

const NamePlanContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}))

const NameText = styled(Typography)({
  textAlign: 'left',
  fontWeight: '600',
})

const UpperNav = ({ open, user }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const {
    groupState: { selectedGroup },
  } = useContext(GroupContext)
  const { adminState } = useContext(AdminContext)
  const { impersonation } = adminState

  const dropDownOpen = Boolean(anchorEl)

  const handleDropDownOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleDropDownClose = () => {
    setAnchorEl(null)
  }

  const renderNavContent = () => {
    if (user?.id) {
      return (
        <RightNavContainer
          onClick={handleDropDownOpen}
          disabled={dropDownOpen}
          disableRipple
          id="upper-nav-menu-button"
          aria-controls={dropDownOpen ? 'upper-nav-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={dropDownOpen ? 'true' : undefined}>
          <ProfileInfoContainer>
            <AvatarNameContainer>
              <AvatarContainer>
                <StyledAvatar
                  src={selectedGroup ? selectedGroup.avatar : user?.avatar}
                  alt="Profile picture"
                />
              </AvatarContainer>
              <NamePlanContainer>
                <NameText variant="body1">
                  {selectedGroup
                    ? selectedGroup.name
                    : (user?.first_name || '') + ' ' + (user?.last_name || '')}
                </NameText>
                <Typography variant="body1">
                  {user?.membership?.plan?.name}{' '}
                  {user.membership.isTrialing ? 'Trial ' : 'Plan'}
                </Typography>
              </NamePlanContainer>
            </AvatarNameContainer>
          </ProfileInfoContainer>
          <CaretContainer dropDownOpen={dropDownOpen}>
            <CaretDown />
          </CaretContainer>
          <NewSettingsMenu
            dropDownOpen={dropDownOpen}
            handleDropDownClose={handleDropDownClose}
            anchorEl={anchorEl}
          />
        </RightNavContainer>
      )
    } else {
      return <UpperNavUnAuth />
    }
  }

  const renderAlertBanner = () => {
    if (impersonation?.isActive) {
      return (
        <BannerToolbar>
          <ImpersonationBanner />
        </BannerToolbar>
      )
    }
  }

  return (
    <StyledAppBar
      position="fixed"
      open={open}
      elevation={0}
      loggedInUser={user?.id}>
      {renderAlertBanner()}
      <StyledToolbar>{renderNavContent()}</StyledToolbar>
    </StyledAppBar>
  )
}

export default UpperNav

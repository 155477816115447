import { useState, useContext } from 'react'
import { styled } from '@mui/system'
import {
  TableRow,
  TableCell,
  Collapse,
  IconButton,
  Box,
  Tooltip,
} from '@mui/material'

import { formatDate } from '../common/helpers'
import Api from '../../services/api'
import { AuthenticationContext } from '../authentication/authenticationContext'
import { AdminContext } from '../admin/adminContext'

import { ReactComponent as TrashIcon } from '../../icons/trash2Icon_16x16.svg'
import { ReactComponent as ArchiveIcon } from '../../icons/archiveIcon_16x16.svg'
import { ReactComponent as LeadContactedIcon } from '../../icons/itemCheckedIcon_16x16.svg'
import { ReactComponent as CaretDownIcon } from '../../icons/caretDown_16x16.svg'

import { ActionsTableCell, CheckboxTableCell } from '../common/TableComponents'
import LeadTypeIcon from './LeadTypeIcon'
import LeadsTableRowExpand from './LeadsTableRowExpand'
import LeadsTags from './LeadsTags'
import TableActionsDropdown from '../common/TableActionsDropdown'
import CheckBoxStyled from '../common/CheckBoxStyled'
import TableCellStyled from '../common/TableCellStyled'

const StyledTableRow = styled(TableRow, {
  shouldForwardProp: (prop) => prop !== 'selected',
})(({ theme, selected }) => ({
  cursor: 'pointer',
  backgroundColor: selected ? theme.palette.primary.lightest : '',
}))

const CollapseTableRow = styled(TableRow, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  backgroundColor: theme.palette.neutral.lightest,
  td: {
    borderBottom: open
      ? `1px solid ${theme.palette.neutral.extraLight}`
      : 'none',
  },
}))

const TableCellContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
})

const TableCellPrimaryContent = styled('span')(({ newItem, boldPrimary }) => ({
  fontWeight: newItem ? '700' : boldPrimary ? '600' : '400',
}))

const TableCellSecondaryContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  marginTop: theme.spacing(1),
  gap: theme.spacing(1),
}))

const StyledCaretIcon = styled(CaretDownIcon, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
  transition: 'transform 0.2s ease-in-out',
  path: {
    stroke: theme.palette.neutral.semiLight,
  },
}))

const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 11),
  boxShadow: `${theme.shape.boxShadow.xsInset}`,
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2, 2),
  },
}))

const LeadsTableRow = ({
  lead,
  handleDeleteLead,
  handleUpdateLeads,
  handleCheckboxChange,
  handleMarkAsViewed,
  selectedLeads,
}) => {
  const [leadLoading, setLeadLoading] = useState(false)
  const [leadUpdating, setLeadUpdating] = useState(false)
  const [open, setOpen] = useState(false)
  const [leadData, setLeadData] = useState(null)
  const { setNotification } = useContext(AuthenticationContext)
  const { adminState } = useContext(AdminContext)

  const handleArchiveLead = async (leadId) => {
    setLeadUpdating(true)
    await handleUpdateLeads([leadId], { archived: !lead.archived })
    setLeadUpdating(false)
  }

  const handleMarkContacted = async (leadId) => {
    setLeadUpdating(true)
    await handleUpdateLeads([leadId], { contacted: !lead.contacted })
    setLeadUpdating(false)
  }

  const menuItems = [
    {
      text: 'Mark Contacted',
      icon: <LeadContactedIcon />,
      onClick: () => handleMarkContacted(lead.id),
      disabled: lead.isGated,
    },
    {
      text: lead.archived ? 'Unarchive Lead' : 'Archive Lead',
      icon: <ArchiveIcon />,
      onClick: () => handleArchiveLead(lead.id),
      loading: leadUpdating,
      disabled: lead.isGated,
    },
    {
      text: 'Delete Lead',
      icon: <TrashIcon />,
      onClick: () => handleDeleteLead(lead.id),
      disabled: lead.isGated,
    },
  ]

  const fetchLeadData = async () => {
    try {
      setLeadLoading(true)

      const isImpersonating = adminState?.impersonation?.isActive
      const reqParams = {
        lead: {
          is_impersonating: isImpersonating,
        },
      }

      const res = await Api.fetchLeadData(lead.id, reqParams)

      if (!res.errors) {
        setLeadData(res)
        setLeadLoading(false)
      } else {
        throw res.errors
      }
    } catch (err) {
      setLeadLoading(false)
      setNotification(err, 'error')
    }
  }

  const handleOpen = () => {
    if (lead.isGated) return

    if (!open && !leadData) {
      handleMarkAsViewed(lead)
      fetchLeadData()
    }

    setOpen(!open)
  }

  const selected = selectedLeads.includes(lead.id)

  const renderRowContent = () => {
    return (
      <StyledTableRow selected={selected}>
        <CheckboxTableCell>
          <CheckBoxStyled
            checked={selected}
            onChange={() => handleCheckboxChange(lead.id)}
            disabled={lead.isGated}
            inputProps={{ 'aria-labelledby': lead.id }}
          />
        </CheckboxTableCell>
        <TableCellStyled width="38px">
          <IconButton
            size="small"
            disabled={lead.isGated}
            onClick={handleOpen}
            aria-expanded={open}
            aria-label="show more">
            <StyledCaretIcon open={open} />
          </IconButton>
        </TableCellStyled>
        <TableCellStyled textWrap="nowrap" width="115px" onClick={handleOpen}>
          <TableCellContent>
            <TableCellPrimaryContent newItem={!lead.viewed}>
              {formatDate(lead.createdAt, lead.utcOffset)}
            </TableCellPrimaryContent>
            <LeadsTags lead={lead} />
          </TableCellContent>
        </TableCellStyled>
        <TableCellStyled
          minWidth="160px"
          width="20%"
          onClick={handleOpen}
          displayBreakpoint="md">
          <TableCellPrimaryContent newItem={!lead.viewed}>
            {lead.event?.talkTitle}
          </TableCellPrimaryContent>
        </TableCellStyled>
        <TableCellStyled
          width="15%"
          onClick={handleOpen}
          displayBreakpoint="lg">
          <TableCellPrimaryContent newItem={!lead.viewed}>
            {lead.isGated ? '******' : lead.organization}
          </TableCellPrimaryContent>
        </TableCellStyled>
        <TableCellStyled
          width="15%"
          onClick={handleOpen}
          displayBreakpoint="lg">
          <TableCellContent>
            <TableCellPrimaryContent newItem={!lead.viewed} boldPrimary={true}>
              {lead.isGated ? '******' : lead.name}
            </TableCellPrimaryContent>
            <TableCellSecondaryContent>
              {lead.isGated ? '******' : lead.title}
            </TableCellSecondaryContent>
          </TableCellContent>
        </TableCellStyled>
        <TableCellStyled minWidth="15%" onClick={handleOpen}>
          <TableCellContent>
            <TableCellPrimaryContent newItem={!lead.viewed} boldPrimary={true}>
              {lead.isGated ? '******' : lead.email}
            </TableCellPrimaryContent>
            <TableCellSecondaryContent>
              {lead.isGated ? '******' : lead.phone}
            </TableCellSecondaryContent>
          </TableCellContent>
        </TableCellStyled>
        <TableCellStyled
          width="115px"
          onClick={handleOpen}
          displayBreakpoint="lg">
          <LeadTypeIcon lead={lead} />
        </TableCellStyled>
        <ActionsTableCell align="center">
          <TableActionsDropdown menuItems={menuItems} />
        </ActionsTableCell>
      </StyledTableRow>
    )
  }

  return (
    <>
      {lead.isGated ? (
        <Tooltip
          title="You have exceeded your lead limit. Please upgrade your plan to unlock this lead."
          followCursor>
          {renderRowContent()}
        </Tooltip>
      ) : (
        renderRowContent()
      )}
      <CollapseTableRow open={open}>
        <TableCell style={{ padding: 0 }} colSpan={9}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <StyledBox>
              <LeadsTableRowExpand
                lead={lead}
                leadSurveyData={leadData}
                leadLoading={leadLoading}
                leadUpdating={leadUpdating}
                handleArchiveLead={handleArchiveLead}
                handleMarkContacted={handleMarkContacted}
                handleDeleteLead={handleDeleteLead}
              />
            </StyledBox>
          </Collapse>
        </TableCell>
      </CollapseTableRow>
    </>
  )
}

export default LeadsTableRow

import Api from '../services/api'
import * as Lead from '../features/leads/helpers'
import { ExportToCsv } from 'export-to-csv'

const cleanAttributeForCsv = (attribute) => {
  if (!attribute) {
    return ''
  }

  return attribute
}

const leadHeaders = [
  'Created At',
  'First Name',
  'Last Name',
  'Talk Title',
  'Talk Organization',
  'Phone',
  'Email',
  'Organization',
  'Job Title',
  'Contacted',
  'Warmth',
  'Booking Lead?',
  'Refer Lead?',
  'Email Opt In?',
]

const leadCsvOptions = (exportTitle) => {
  return {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: false,
    title: exportTitle,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: false,
    headers: leadHeaders,
    filename: exportTitle,
  }
}

const serializeLeads = (leads) => {
  return leads.map((lead) => {
    return [
      lead.createdAt,
      cleanAttributeForCsv(lead.firstName),
      cleanAttributeForCsv(lead.lastName),
      cleanAttributeForCsv(makeTalkTitle(lead)),
      cleanAttributeForCsv(makeTalkOrganization(lead)),
      cleanAttributeForCsv(lead.phone),
      cleanAttributeForCsv(lead.email),
      cleanAttributeForCsv(lead.organization),
      cleanAttributeForCsv(lead.title),
      lead.contacted ? 'yes' : 'no',
      Lead.calcLeadWarmth(lead),
      Lead.isBookingLead(lead.leadTypes) ? 'yes' : '',
      Lead.isReferLead(lead.leadTypes) ? 'yes' : '',
      Lead.isOptIn(lead.leadTypes) ? 'yes' : '',
    ]
  })
}

const makeTalkTitle = (lead) => {
  return lead.event ? lead.event.talkTitle : lead.talkTitle
}

const makeTalkOrganization = (lead) => {
  return lead.event ? lead.event.talkOrganization : lead.talkOrganization
}

export const exportLeads = async (leads) => {
  const exportTitle = 'talkadot-leads-export'

  if (leads.length <= 0) {
    return {
      success: false,
      errors: 'There are no selected leads',
    }
  }

  const csvData = serializeLeads(leads)
  const options = leadCsvOptions(exportTitle)
  const csvExporter = new ExportToCsv(options)

  csvExporter.generateCsv(csvData)

  return { success: true }
}

export const exportEventLeads = async ({ event, group }) => {
  const exportTitle = `talkadot-leads-export-${
    event.talkTitle ? event.talkTitle : 'unnamed-event'
  }-${event.date}`

  const params = {
    event_leads: {
      event_id: event.id,
    },
  }

  let res

  if (group) {
    res = await Api.fetchGroupEventLeads({
      ...params,
      group_id: group.id,
    })
  } else {
    res = await Api.fetchEventLeads(params)
  }

  if (!res.errors) {
    if (res?.length <= 0) {
      return {
        success: false,
        errors: 'There are no usable leads for this session',
      }
    }

    const csvData = serializeLeads(res)
    const options = leadCsvOptions(exportTitle)
    const csvExporter = new ExportToCsv(options)

    csvExporter.generateCsv(csvData)

    return { success: true }
  } else {
    return res.errors
  }
}

export const exportEventRawData = async ({ event, group }) => {
  const exportTitle = `talkadot-raw-data-export-${
    event.talkTitle ? event.talkTitle : 'unnamed-event'
  }-${event.date}`

  const params = {
    event_answers: {
      event_id: event.id,
    },
  }

  let res

  if (group) {
    res = await Api.fetchGroupEventRawAnswers({
      ...params,
      group_id: group.id,
    })
  } else {
    res = await Api.fetchEventRawAnswers(params)
  }

  if (!res.errors) {
    if (res?.length <= 0) {
      return {
        success: false,
        errors: 'There are no testimonials for this session',
      }
    }

    const headers = [
      'Question',
      'Answer',
      'Name',
      'Email',
      'Job Title',
      'Organization',
    ]

    const csvData = res.map((answerData) => {
      return [
        cleanAttributeForCsv(answerData.question),
        cleanAttributeForCsv(answerData.answer),
        cleanAttributeForCsv(answerData.name),
        cleanAttributeForCsv(answerData.email),
        cleanAttributeForCsv(answerData.title),
        cleanAttributeForCsv(answerData.organization),
      ]
    })

    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      title: exportTitle,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
      headers: headers,
      filename: exportTitle,
    }
    const csvExporter = new ExportToCsv(options)
    csvExporter.generateCsv(csvData)

    return { success: true }
  } else {
    return res.errors
  }
}

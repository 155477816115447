import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Skeleton from '@mui/material/Skeleton'

const TalkEngagement = ({
  isLoading,
  engagementBreakdown,
  isProfile = false,
  speaker,
}) => {
  const theme = useTheme()

  const ACTIONABLE = 'actionable'
  const ENGAGING = 'engaging'
  const INSPIRING = 'inspiring'
  const RELEVANT = 'relevant'
  const INTERACTIVE = 'interactive'

  const isValidLabel = (label) => {
    return !label?.includes('{{') && label?.trim().split(/\s+/).length <= 2
  }

  const formatLabelFromLegacyKeywords = (content) => {
    if (content.includes(ACTIONABLE)) {
      return ACTIONABLE
    }

    if (content.includes(ENGAGING)) {
      return ENGAGING
    }

    if (content.includes(INSPIRING)) {
      return INSPIRING
    }

    if (content.includes(RELEVANT)) {
      return RELEVANT
    }

    if (content.includes(INTERACTIVE)) {
      return INTERACTIVE
    }

    return
  }

  const formatLabel = (label) => {
    let formattedLabel = formatLabelFromLegacyKeywords(label)

    if (!formattedLabel) {
      return label
    }

    return formattedLabel
  }

  const sortedEngagementBreakdown = engagementBreakdown
    .map((engagementStat, i) => {
      if (engagementStat.label) {
        return {
          ...engagementStat,
          label: formatLabel(engagementStat.label),
        }
      } else {
        return null
      }
    })
    .filter((engagementStat) =>
      isValidLabel(engagementStat.label) ? { ...engagementStat } : null
    )
    .sort((a, b) => parseFloat(b?.avg_value) - parseFloat(a?.avg_value))

  const makeBarValue = (engagementStatValue) => {
    if (!engagementStatValue) {
      return
    } else {
      // All the numbers are on a scale of 1-5, so need to adjust this for a scale of 100%
      return parseFloat(engagementStatValue * 20).toFixed(1)
    }
  }

  const COLOR_CODES = [
    theme.palette.error.main,
    theme.palette.success.semiDark,
    theme.palette.primary.main,
    theme.palette.secondary.main,
    theme.palette.error.main,
    theme.palette.success.semiDark,
    theme.palette.primary.main,
    theme.palette.secondary.main,
  ]

  const renderEngagementRow = (engagementType, index) => {
    const barValue = makeBarValue(engagementType.avg_value)

    return (
      engagementType.label && (
        <div className="engagement-progress__container" key={index}>
          <div>
            <Typography
              variant="body2"
              component="div"
              id="engagement-type-label"
              sx={{ textTransform: 'capitalize' }}>
              {engagementType.label}
            </Typography>
          </div>
          <div className="engagement-progress__outer-bar">
            {barValue ? (
              <div
                className="engagement-progress__inner-bar"
                style={{
                  backgroundColor: COLOR_CODES[index],
                  width: `${barValue}%`,
                }}>
                {barValue}%
              </div>
            ) : (
              <div>N/A</div>
            )}
          </div>
        </div>
      )
    )
  }

  const renderLoadingState = () => (
    <Skeleton width={400} height={200} variant="rectangular" />
  )

  const renderEngagementStats = () => (
    <div className="stat-widget-section" id="talk-engagement-section">
      {engagementBreakdown?.length > 0 && (
        <Typography
          variant="h6"
          component="div"
          className="stat-percentage widget-title"
          id="talk-engagement-title">
          <b>
            {isProfile
              ? `${speaker?.name}'s top attributes`
              : 'Attendees found this session'}
          </b>
        </Typography>
      )}
      <div className="stat-widget-section__widgets">
        <div className="engagement-progress__list">
          {sortedEngagementBreakdown?.slice(0, 4)?.map((engagementType, i) => {
            return renderEngagementRow(engagementType, i)
          })}
        </div>
      </div>
    </div>
  )

  return isLoading ? renderLoadingState() : renderEngagementStats()
}

export default TalkEngagement
